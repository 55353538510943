import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap'
import Sidebar from '../Components/Sidebar'
import Header from '../Components/Header'
import toast, { Toaster } from 'react-hot-toast';

//validations
import { validateProfileEmail,validateProfilePass } from '../Validations/LoginValidate'

//Actions
import { profileSettings } from '../Actions/LoginActions'
import { CreateAllocationHooks, EditAllocationHooks } from '../hooks/Usebackendaxios';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { API_URL } from '../Config/env';
import { AddTier } from '../hooks/UseStake';

function Editallocation() {
    const navigate = useHistory();
    const history = useHistory();
    const location = useLocation();
    const [oldPassword,setOldPassword] = useState('')
    const [newPassword,setNewPassword] = useState('')
    const [passwordeye,setPasswordeye] = useState(false)
    const [newpasswordeye,setNewPasswordeye] = useState(false)
    const [errors, setErrors] = useState('')
    const [newEmail,setNewEmail] = useState('');

    //dev-muruga
    const [Type , setType] = useState("");
    const [multiplier , setMultiplier] = useState(0);
    const [allocation , setAllocation] = useState(0);
    const [fileerror , setFileerror] = useState("");
    
    const [file, setFile] = useState();
    const [image , setImage] = useState();
    const [min , setMin] = useState(0);
    const [max , setMax] = useState(0);

    const [percentage , setPercentage] = useState(0);
    const [error , setError] = useState(0);


    useEffect(() => {
        console.log("locationdata" , location?.state);
        setType(location?.state?.name);
        setAllocation(location?.state?.allocation);
        setMultiplier(parseFloat(location?.state?.multiplier));
        setFile(API_URL + location?.state?.image);
        setMin(location?.state?.min);
        setMax(location?.state?.max);
        setPercentage(location?.state?.percentage)
    } , [])

     const ValidateFile = (data) => {
        try{
            var fileName = data.name;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if(extFile=="jpg" || extFile=="jpeg" || extFile=="png" || extFile=="webp" || extFile=="svg"){
                return ""
            }
            else{
                return "Invalid file format"
            }
        }
        catch(e){
            console.log("Error on validate filer" , e);
        }
    }

    function handleChange(e) {
        let validate = ValidateFile(e?.target?.files[0])
        if(validate){
            setFile();
            setFileerror(validate);
            setImage()
        }
        else {
            console.log(e.target.files);
            setFile(URL.createObjectURL(e.target.files[0]));
            setFileerror("");
            setImage(e?.target?.files[0]);
        }
    }

    const handleeditallocation = async() => {
        // debugger
        let err = error;
        if(Type?.length > 0){
            err = {...err ,  ["type"] : ""}
         }
         else{
            err = {...err ,  ["type"] : "Field is required"}
         }


         if(multiplier){
            err = {...err ,  ["multiplier"] : ""}
         }
         else{
            err = {...err ,  ["multiplier"] : "Field is required"}
         }

        //  if(allocation){
        //     err = {...err ,  ["allcation"] : ""}
        //  }
        //  else{
        //     err = {...err ,  ["allcation"] : "Field is required"}
        //  }


         if(min){
            err = {...err ,  ["min"] : ""}
         }
         else{
            err = {...err ,  ["min"] : "Field is required"}
         }

         if(max){
            err = {...err ,  ["max"] : ""}
         }
         else{
            err = {...err ,  ["max"] : "Field is required"}
         }

         if(percentage){
            err = {...err ,  ["percentage"] : ""}
         }
         else{
            err = {...err ,  ["percentage"] : "Field is required"}
         }

         setError(err)
        //  console.log("dafasds" , Type && !error?.type && multiplier && !error?.multiplier && allocation && !error?.allcation && image && min && !error?.min && max && !error?.max
        //  && percentage && !error?.percentage);
        if(Type && !error?.type && multiplier && !error?.multiplier  && !error?.allcation && min && !error?.min && max && !error?.max
                && percentage && !error?.percentage){
            let formdata = new FormData();
        formdata.append("name" , Type);
        formdata.append("multiplier" , multiplier);
        formdata.append("allocation" , allocation);
        formdata.append("image" , image? image : location?.state?.image)
        formdata.append("id" , location?.state?._id);
        formdata.append("min" ,  min);
        formdata.append("max" , max);
        formdata.append("percentage" , percentage);
        formdata.append("pid" , location?.state?.pid)
        let payload = {
            "name" : Type,
            "multiplier" : multiplier,
            "allocation" : allocation,
            "min" :  min,
            "max" : max,
            "percentage" : percentage,
            "pid" : location?.state?.pid
        }
        let result = await AddTier(payload , formdata);
        
        console.log("result" , result);
        }
    }

  return (
    <>
    <Container fluid className='common_bg position-relative'>
    <div className='liner'></div>

    <Row>
        <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
            <Sidebar/>
        </Col>
        <Col xl={10} lg={12} className='pe-3 ps-lg-4'>
            <Header title={'Edit Tier'}/>
            <div className='mt-5 profile_holder'>
            <Button className='orange_small_primary mb-4' onClick={() => history.goBack()}> <span>Back</span> </Button>

                <Row>

                <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Type</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type={'text'} placeholder='Enter Type' className='rp_singleInput flex-grow-1'
                          value={Type}
                          onChange={(e) => {
                            setType(e?.target?.value);

                            if(e?.target?.value?.length){
                                setError({...error ,  ["type"] : ""})
                            }
                            else{
                                setError({...error ,  ["type"] : "Field is required"})
                            }
                          }} />

                    </div>
                    <span className="text-danger f-12 d-block text-left">
                                    {error?.type}
                                  </span>
                </div>
                    </Col>

                    <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Multiplier Count</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type="number" placeholder='Enter Multiplier Count' className='rp_singleInput flex-grow-1'
                         value={multiplier}
                         onChange={(e) => {
                          setMultiplier(e?.target?.value);

                          if(e?.target?.value){
                            setError({...error ,  ["multiplier"] : ""})
                        }
                        else{
                            setError({...error ,  ["multiplier"] : "Field is required"})
                        }
                         }} 
                          />

                        {/* <i class={!passwordeye ? 'fa-solid fa-eye-slash' : "fa-solid fa-eye" } onClick={()=> setPasswordeye(!passwordeye)}/> */}
                    </div>
                    <span className="text-danger f-12 d-block text-left">
                                    {error?.multiplier}
                                  </span>
                </div>
                    </Col>

                    {/* <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Allocation</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type="number" placeholder='Enter New Password' className='rp_singleInput flex-grow-1' 
                        value={allocation}
                        onChange={(e) => {
                          setAllocation(e?.target?.value);
                          if(e?.target?.value){
                            setError({...error ,  ["allcation"] : ""})
                        }
                        else{
                            setError({...error ,  ["allcation"] : "Field is required"})
                        }
                          
                        }}
                        />

                    </div>
                    <span className="text-danger f-12 d-block text-left">
                                    {error?.allcation}
                                  </span>
                </div>
                    </Col> */}

                    <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Minimum </p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type="number" placeholder='Enter New Password' className='rp_singleInput flex-grow-1' 
                        value={min}
                        onChange={(e) => {
                          setMin(e?.target?.value);

                          if(e?.target?.value){
                            setError({...error ,  ["max"] : ""})
                        }
                        else{
                            setError({...error ,  ["max"] : "Field is required"})
                        }
                        }}
                        />

                        {/* <i class={!newpasswordeye ? 'fa-solid fa-eye-slash' : "fa-solid fa-eye" } onClick={()=> setNewPasswordeye(!newpasswordeye)}/> */}
                    </div>
                    <span className="text-danger f-12 d-block text-left">
                                    {error?.max}
                                  </span>
                </div>
                    </Col>


                    <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Maximum </p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type="number" placeholder='Enter New Password' className='rp_singleInput flex-grow-1' 
                        value={max}
                        onChange={(e) => {
                          setMax(e?.target?.value);
                          if(e?.target?.value){
                            setError({...error ,  ["min"] : ""})
                        }
                        else{
                            setError({...error ,  ["min"] : "Field is required"})
                        }
                          
                        }}
                        />

                        {/* <i class={!newpasswordeye ? 'fa-solid fa-eye-slash' : "fa-solid fa-eye" } onClick={()=> setNewPasswordeye(!newpasswordeye)}/> */}
                    </div>
                    <span className="text-danger f-12 d-block text-left">
                                    {error?.min}
                                  </span>
                </div>
                    </Col>

                    <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder'>
                    <p className='rp_label mb-2'>Percentage</p>
                    <div className='rp_input_holder rounded-2 py-2 px-3 d-flex justify-content-start align-items-center gap-2'>
                        <input type="number" placeholder='Enter New Password' className='rp_singleInput flex-grow-1' 
                        // max={10}
                        value={percentage}
                        onChange={(e) => {
                          setPercentage(e?.target?.value);
                          if(e?.target?.value){
                            setError({...error ,  ["percentage"] : ""})
                        }
                        else{
                            setError({...error ,  ["percentage"] : "Field is required"})
                        }
                        }}
                        />

                        {/* <i class={!newpasswordeye ? 'fa-solid fa-eye-slash' : "fa-solid fa-eye" } onClick={()=> setNewPasswordeye(!newpasswordeye)}/> */}
                    </div>
                    <span className="text-danger f-12 d-block text-left">
                                    {error?.percentage}
                                  </span>
                </div>
                    </Col>
                    <Col lg={7} md={8} sm={10} className='mb-4'>
                    <div className='rp_singleinput_holder mb-3'>
                                        <p className='rp_label mb-2'>Image</p>
                                        <div className="poss">
                                        <button className='orange_small_primary uploadimg mb-4' type='button'>Upload Image</button>
                                        <input type="file" className="browseinput" onChange={handleChange} />
                                        </div>
                                        <img src={file} className="img-fluid mt-3 hw" />
                                    </div>
                                    <p className='text-danger'>{fileerror}</p>
                                    </Col>
                                    <div className="pt-3">
                                        <button className='orange_small_primary' disabled = {!Type || !allocation || !multiplier} onClick={handleeditallocation}>Submit</button>
                                    </div>
                  
                </Row>

                {/* <button className='orange_small_primary'  onClick={() => {handlePassword() }}>Submit</button> */}

                
                
            </div>
        </Col>
    </Row>

    </Container>
    </>
  )
}

export default Editallocation;