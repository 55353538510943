import React, { useEffect, useState } from 'react'
import { Col, Container, Row, InputGroup, FormControl, Form, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'react-bootstrap'
import Header from '../Components/Header'
import Sidebar from '../Components/Sidebar'
import { useDispatch, useSelector } from 'react-redux'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//useContract
import { Checkaddress, GetAdminfee, Approvetoken, isFloat, depositTokens, UseTokenInfo, getXLaunchAddress, DeployLaunchReceiptToken, GetChainIndex } from '../hooks/useContract'

//Lib 
import { isEmpty, ObjectIsempty } from '../Lib/isEmpty';
import { getWalletAddress } from '../Lib/localStorage'
import { toFixedNumber } from '../Lib/FixedNumber'
import { padToDigits } from '../Lib/DateTimeHelper'

//Config
import { CHAINS, ZEROTH_ADDRESS, ONEDAYINSECONDS } from '../Config/env';

//hooks
import { getChainId, CURRENT_RPC_URL, CURRENT_CHAIN_ID } from '../hooks/useWeb3';

//Actions
import { getFees } from '../Actions/FeeSettingsActions'

//validation
import { urlvalidation } from '../Validations/LaunchpadValidate'
import { GetAllocationHooks } from '../hooks/Usebackendaxios'
import { createNativePresale } from '../hooks/UseNativesale'
// import { getStakingAddress } from '../../../frontend/src/Hooks/useStake'


function CreateNativeLaunchpad() {
    const [currentStep, setCurrentstep] = useState(1);
    const [isPancake, setIsPancake] = useState(false);
    const [isVested, setIsVested] = useState(false);
    const [tokenaddress, setTokenaddress] = useState('')
    const [errors, setErrors] = useState({})
    const [tokeninfo, setTokeninfo] = useState({})
    const [approvebutton, setApprovebutton] = useState(false)
    const [button1, setButton1] = useState(true)
    const [button2, setButton2] = useState(true)
    const [button3, setButton3] = useState(true)
    const [poolfee, setPoolfee] = useState('')
    console.log("poolfee", poolfee);
    const [currency, setCurrency] = useState("USDT")
    const [getFee, setGetFee] = useState([])
    const [CheckedInd, setCheckedInd] = useState(0)
    const [min, setMin] = useState(0)
    const [max, setMax] = useState(0)
    const [tokenFee, setTokenFee] = useState(0)
    const [currencyFee, setCurrencyFee] = useState(0)
    const [softcap, setSoftcap] = useState(0)
    const [hardcap, setHardcap] = useState(0)
    const [presaleRate, setPresaleRate] = useState(0)
    const [unlockOn, setUnlockOn] = useState(0)
    const [liquidityPercent, setLiquidityPercent] = useState('')
    const [vestInterval, setVestInterval] = useState(0)
    const [vestPercent, setVestPercent] = useState(0)
    const [isWhitelisted, setIsWhitelisted] = useState(false)
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [logo, setLogo] = useState('');
    const [description, setDescription] = useState('');
    const [website, setWebsite] = useState('');
    const [twitter, setTwitter] = useState('');
    const [telegram, setTelegram] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [discord, setDiscord] = useState('');
    const [reddit, setReddit] = useState('');
    const [youtube, setYoutube] = useState('');
    const [banner, setBanner] = useState('');
    const [github, setGithub] = useState('');
    const [deposit, setDeposit] = useState(0);
    const [isDeposited, setIsDeposited] = useState(false)
    const [symbol, setSymbol] = useState('');
    const [createdSale, setCreatedSale] = useState('');
    const [WithTokenaddress, setWithTokenaddress] = useState(CHAINS[getChainId()].USDT);
    const [TokenDecimal, setTokenDecimal] = useState(0)
    const [affliateFee, setAffliateFee] = useState(3);
    const [LaunchpadType, setLaunchpadType] = useState(true)
    const [isWithoutToken, setIsWithoutToken] = useState(false)
    const [isToken, setIsToken] = useState(false)
    const [userFee, setUserFee] = useState(0);
    const [pancakeRate, setPancakeRate] = useState(0);
    const [publicAmt, setPublicAmt] = useState(0);
    const [whitelistAmt, setWhitelistAmt] = useState(0);
    const [isstepcompleted, setIsstepcompleted] = useState(false);
    const [lockstakeaddress, setLockstakeaddress] = useState('')
    const [launchReceipt, setLaunchReceipt] = useState('');

    const [allocationlist , setAllocationlist]= useState([]);
    const [selectedallocation , setSelectedallocation] = useState();
    const [selectedtire , setSelectedtire] = useState();
    const [tokenprice , setTokenprice] = useState(0);
    const [listingrate , setListingrate] = useState(0);

    const [saletype , setSaletype] = useState("");
    const [firstclaim , setFirstclaim] = useState(0);



    const { networkSwitch } = useSelector((state) => state.networkSwitching)
    console.log("networkSwitching", networkSwitch)

    useEffect(() => {
        // setCurrency(CHAINS[getChainId()].SYMBOL)
        // setWithTokenaddress(CHAINS[getChainId()][`${CHAINS[getChainId()].SYMBOL}`]);
    }, [networkSwitch])

    const GetAllocationlist = async() => {
        const Proxydata = await GetAllocationHooks();
        console.log("Proxydata", Proxydata);
        setAllocationlist(Proxydata?.data?.record);
        setSelectedallocation(Proxydata?.data?.record[0]?.name)
        setSelectedtire(Proxydata?.data?.record[0]?.min)
    }


    const settokenaddress = async (value) => {
        setTokenaddress(value);
        const tokendata = await Checkaddress(value);
        console.log("tokendata", tokendata);
        if (tokendata?.isValid) {
            setTokeninfo(tokendata.tokeninfo)
            setErrors({});
            setApprovebutton(tokendata.allowance)
            setButton1(false)
        }
        else {
            let formData = { ...errors, ["tokenaddress"]: "Invalid token address !" };
            setErrors(formData);
            setTokeninfo({})
        }
    }

    const GetTokenAddress = (value) => {
        let Withtokenaddress;
        if (value === CHAINS[getChainId()].SYMBOL) {
            Withtokenaddress = CHAINS[getChainId()][`${CHAINS[getChainId()].SYMBOL}`]
        } else if (value === "DAI") {
            Withtokenaddress = CHAINS[getChainId()].DAI
        } else if (value === "USDC") {
            Withtokenaddress = CHAINS[getChainId()].USDC
        } else if (value === "USDT") {
            Withtokenaddress = CHAINS[getChainId()].USDT
        }

        console.log("WithTokenaddress", Withtokenaddress);
        setWithTokenaddress(Withtokenaddress);

    }

    console.log("withTokenADDDDD", WithTokenaddress)

    useEffect(() => {
        GetAllocationlist()
        getFeeSettings()
        getfee()
        getStakingAddress()


        GetAllocationlist()

    }, [])

    const getFeeSettings = async () => {
        let get = await getFees();
        console.log("getFee", get);
        if (get?.data?.data?.status == true) {
            let data = get?.data?.data?.data
            console.log("DATA", data)
            setCurrencyFee(data[0]?.currencyvalue)
            // setTokenFee(data[0]?.tokenvalue)
            setGetFee(get?.data?.data?.data);
            console.log("getFee_getFee", get?.data?.data?.data)
        }
    }


    const getfee = async () => {
        try {
            let fee = await GetAdminfee();
            setPoolfee(parseFloat(fee?.deploymentfee) / 10 ** 18)
        }
        catch (err) {
            console.log("setPoolfee_err", err)
        }
    }

    const approvetoken = async () => {
        let result = await Approvetoken(tokenaddress);
        console.log("result", result)
        if (result) {
            setApprovebutton(true)
        }
    }


    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);
        return currentDate.getTime() < selectedDate.getTime();
    };


    const handledisable = () => {

        setButton2(false)
        var isvalid = true;
        var i = 1;
        console.log("isvalid_isvalid", isvalid, ObjectIsempty(errors), errors, presaleRate,  hardcap, startDate, endDate);


        if (ObjectIsempty(errors) &&  hardcap && startDate && endDate) {
            isvalid = false
            // if (this.state.isAffiliate) {
            //     isvalid = true
            //     if (this.state.affliateFee) {
            //         isvalid = false
            //     }
            // }
            console.log("vest1", isvalid);

            console.log("this.state.isVested", isVested)
            if (isVested) {
                isvalid = true;
                console.log("vest4", isvalid);
                if (vestInterval && vestPercent) {
                    isvalid = false
                    console.log("vest5", isvalid);
                }
            }

        }
        setButton2(isvalid);
        console.log("isvalid", isvalid);
        return isvalid;
    }



    const validations = () => {

        var errors = {}
        var rd = new RegExp(/^\d+$/);
        if (!rd.test(parseFloat(hardcap))) {
            errors.hardcap = "Invalid Total Selling Amount"
        }
        else if (isNaN(hardcap)) {
            errors.hardcap = "Invalid Total Selling Amount"
        }
        else if (!hardcap || hardcap == 0) {
            errors.hardcap = "Total Selling Amount field is required"
        }
        // else if (parseFloat(hardcap) < parseFloat(softcap)) {
        //     errors.hardcap = "Total Selling Amount must be greater than the softcap"
        // }
        // else if (parseFloat(hardcap) < parseFloat(publicAmt) + parseFloat(whitelistAmt)) {
        //     errors.hardcap = "Total Selling Amount must be greater than the publicAmt & whitelistAmt"
        // }
        // else if (parseFloat(hardcap) !== parseFloat(publicAmt) + parseFloat(whitelistAmt)) {
        //     errors.hardcap = "Total Selling Amount must be equal to sum of public Amount & whitelist Amount"
        // }
        else {
            errors.hardcap = ""
        }

        console.log("thisstatesoft", isEmpty(softcap))
        // var rd = new RegExp(/^\d+$/);
        var rx = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
        // if (!rx.test(parseFloat(softcap))) {
        //     errors.softcap = "Invalid softcap !"
        // }
        // else if (isNaN(softcap)) {
        //     errors.softcap = "Invalid softcap"
        // }

        // else if (isEmpty(softcap) || softcap === 0) {
        //     console.log("thisstatesoftCap", isEmpty(softcap))
        //     errors.softcap = "Softcap field is required"
        // }
        // else {
        //     errors.softcap = ""
        // }


        var rx = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
        // if (!rx.test(parseFloat(publicAmt))) {
        //     errors.publicAmt = "Invalid  public Amount !"
        // }
        // else if (isNaN(publicAmt)) {
        //     errors.publicAmt = "Invalid  public Amount"
        // }

        // else if (isEmpty(publicAmt) || publicAmt === 0) {
        //     console.log("thisstatesoftCap", isEmpty(publicAmt))
        //     errors.publicAmt = "Public Selling Amount field is required"
        // }

        // else if (parseFloat(publicAmt) > parseFloat(hardcap)) {
        //     errors.publicAmt = "Public users Amount must be lesser than the Total selling amount"

        // }
        // else {
        //     errors.publicAmt = ""
        // }


        var rx = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
        // if (!rx.test(parseFloat(whitelistAmt))) {
        //     errors.whitelistAmt = "Invalid Whitelist Amount !"
        // }
        // else if (isNaN(whitelistAmt)) {
        //     errors.whitelistAmt = "Invalid Whitelist Amount"
        // }

        // else if (isEmpty(whitelistAmt) || whitelistAmt === 0) {
        //     console.log("thisstatesoftCap", isEmpty(whitelistAmt))
        //     errors.whitelistAmt = "Whitelist Selling Amount field is required"
        // }

        // else if (parseFloat(whitelistAmt) > parseFloat(hardcap)) {
        //     errors.whitelistAmt = "Whitelist users Amount must be lesser than the Total selling amount"

        // }
        // else {
        //     errors.whitelistAmt = ""
        // }


        // if (this.state.isAffiliate === true && !this.state.affliateFee || isNaN(this.state.affliateFee)) {
        //     errors.affliateFee = "Invalid Affiliate percentage!!"

        // }
        // else {
        //     errors.affliateFee = ""
        // }


        if (isPancake) {
            var rx = new RegExp(/^\d+$/);
            if (!rx.test(unlockOn)) {
                errors.lockingday = "Invalid Locking Days !"

            }
            else if (isEmpty(unlockOn)) {
                errors.lockingday = "Locking Days field is required!"
            }
            // else if (unlockOn < 30) {
            //     errors.lockingday = "Locking Days should be greater than 30 days"
            // }
            else {
                errors.lockingday = ""
            }



            if (isNaN(liquidityPercent) || liquidityPercent < 51 || liquidityPercent > 100) {
                errors.liquidity = "Invalid DonutSwap Liquidity !"
            }
            else {
                errors.liquidity = ""
            }
        }

        if (new Date(startDate).getTime() >= new Date().getTime()) {
            errors.startDate = ""
        }
        else if (isEmpty(startDate)) {
            errors.startDate = "Start Date field is required!"
        }
        else {
            errors.startDate = "Start Time needs to be after now"
        }
        if (endDate) {

            if (Date.parse(startDate) > endDate) {
                let formData = { ...errors, ["startdate"]: "Start Time must be < End Time" };
            }
            if (endDate > Date.parse(startDate)) {
                errors.endDate = ""
            }

        }

        if (Date.parse(endDate) > Date.now()) {
            errors.endDate = ""
        }
        else if (isEmpty(endDate)) {
            errors.endDate = "End Date field is required!"
        }
        else {
            errors.endDate = "End Time needs to be after now"
        }
        if (startDate) {
            if (Date.parse(endDate) <= startDate) {
                console.log("invalideee");
                errors.endDate = "End Time must be > Start Time"
            }
            if (startDate < Date.parse(endDate)) {
                errors.startDate = ""
                errors.endDate = ""

            }
        }
        if (isVested) {
            var rx = new RegExp(/^\d+$/);
            if (!rx.test(vestInterval)) {
                errors.vestInterval = "Invalid Vesting Period Days !"
            }
            else {
                errors.vestInterval = ""
            }


            if (isNaN(vestPercent) || vestPercent == '' || vestPercent < 0 || vestPercent > 100) {
                errors.vestPercent = "Invalid Rewards % per Vesting Period !"
            }
            else {
                errors.vestPercent = ""
            }

        }
        console.log("validation_errors", errors)
        setErrors(errors)
    }


    const handledisableurlbutton = ({
        description,
        logo,
        banner,
        website,
        twitter,
        facebook,
        telegram,
        github,
        instagram,
        discord,
        reddit,
        youtube
    }) => {
        var isvalid = true;
        console.log("handledisableurlbutton", isvalid)
        if (logo && banner  && twitter&& telegram  && description) {
            isvalid = false;
        }
        console.log("handledisableurlbutton1", isvalid)
        setButton3(isvalid)
    }

    const calculateDepositTokens = () => {
        var DepositToken = hardcap*tokenprice
        console.log("DepositToken", DepositToken, tokeninfo?.totalSupply, parseFloat(tokeninfo?.totalSupply) / 10 ** 18)
        setDeposit(DepositToken);

    }

    const DepositTokens = async () => {
        const account = getWalletAddress()
        const tokenAmount = toFixedNumber(deposit * 10 ** parseFloat(tokeninfo?.decimals)).toString()
        const isDeposit = await depositTokens(tokenaddress, createdSale, tokenAmount, account)
        setIsDeposited(isDeposit);
        console.log("isDeposit", isDeposit)

        // window.onbeforeunload = undefined
        // window.onpopstate = undefined
    }

    const renderDeposit = () => {
        return (deposit > 0 ?
            <button onClick={() => { DepositTokens() }} className="orange_small_primary">Deposit {parseFloat(deposit).toFixed(2)} {tokeninfo?.symbol}</button> :
            <button onClick={() => { calculateDepositTokens() }} className="orange_small_primary">Calculate</button>)
    }

    const useWithtokenInfo = async () => {
        console.log("address", WithTokenaddress, WithTokenaddress != ZEROTH_ADDRESS)
        if (WithTokenaddress != ZEROTH_ADDRESS) {
            var usewithToken = await UseTokenInfo(WithTokenaddress)
            console.log("usewithToken", usewithToken)
            setTokenDecimal(usewithToken?.decimals);
        }
        else {
            setTokenDecimal(18)
        }
    }


    const getStakingAddress = () => {
        try {
            const index = GetChainIndex();
            const ContractAddress = CHAINS[index].LOCKEDSTAKING;
            console.log("ContractAddress", ContractAddress);
            setLockstakeaddress(ContractAddress)
        } catch (e) {
            console.log("error", e);
        }
    }


    const CreateSale = async () => {
        await useWithtokenInfo()
        // window.onbeforeunload = () => window.confirm("You have'nt deposited tokens to start presale !")
        // window.onpopstate = () => window.confirm("You have'nt deposited tokens to start presale !")
        console.log("call");
        let Addresses = [];
        let Values = [];
        let Details = [];
        let isSetter = [];

        const account = getWalletAddress()
        console.log("account1", account);

        const startTime = startDate;
        const endTime = endDate;


        const CHAIN_ID = CURRENT_CHAIN_ID();
        const CURRENT_RPC = CURRENT_RPC_URL();
        let ROUTER;
        CHAINS.map((data) => {
            { console.log("datas", data) }
            if ((((data.CHAIN_ID == CHAIN_ID) && (data.RPC_URL == CURRENT_RPC)) && data.ROUTER) != false)
                ROUTER = ((data.CHAIN_ID == CHAIN_ID) && (data.RPC_URL == CURRENT_RPC)) && data.ROUTER;

        })

        const startDiff = parseInt(parseInt((new Date(startTime).getTime())) / 1000);
        const endDiff = parseInt(parseInt((new Date(endTime).getTime())) / 1000);

        const fee = tokenFee
        const bnbfee = currencyFee


        const publicUserAmt = publicAmt
        const whitelistUserAmt = whitelistAmt
        // const XLaunchAddress = getXLaunchAddress()

        // _token 0
        //_router 1
        //owner 2
        //UsewithToken 3
        Addresses.push(tokenaddress);
        Addresses.push(ROUTER);
        Addresses.push(account);
        Addresses.push(WithTokenaddress)
        // Addresses.push(lockstakeaddress)
        // Addresses.push(XLaunchAddress)
        // Addresses.push(launchReceipt)
        console.log("addresses", Addresses);

        //_min 0 
        //_max 1
        //_rate 2
        // _soft  3
        // _hard 4
        //_pancakeRate  5
        //_unlockon  6
        // _percent 7
        // _start 8
        //_end 9
        //_vestPercent 10
        //_vestInterval 11
        //_useFee 12
        //_affliateFee 13
        //_minAff 14
        //_maxAff 15
        //_fee (token fee )16
        //_bnbfee 17
        Values.push(toFixedNumber(parseFloat(min) * 10 ** 18).toString());
        Values.push(toFixedNumber(parseFloat(max) * 10 ** 18).toString());
        Values.push(toFixedNumber(tokenprice * 10 ** 18).toString());
        Values.push("0")             //(toFixedNumber(softcap * 10 ** 18).toString());
        Values.push(toFixedNumber(hardcap * 10 ** 18).toString());
        Values.push(toFixedNumber(parseFloat(listingrate) * 10 ** 18).toString());
        Values.push("0")            //(unlockOn.toString());
        Values.push("0")              //(liquidityPercent.toString());
        Values.push(startDiff.toString());
        Values.push(endDiff.toString());
        Values.push((parseFloat(vestPercent) * 100).toString());
        Values.push((vestInterval * ONEDAYINSECONDS).toString());

        
        Values.push((userFee).toString())
        Values.push((0 * 10 ** 18).toString())
        Values.push((0).toString())
        Values.push("115792089237316195423570985008687907853269984665640564039457584007913129639935")
        Values.push((0).toString())
        Values.push((bnbfee * 10 ** 18).toString())
        Values.push((parseFloat(firstclaim) * 100).toString());
        // Values.push(toFixedNumber(publicUserAmt * 10 ** 18).toString());
        // Values.push(toFixedNumber(whitelistUserAmt * 10 ** 18).toString());
        // Values.push(parseFloat(selectedtire)*100);

        // Values.push(feeind.toString())
        // Values.push(toFixedNumber((parseFloat(allocationlist[1]?.min))*100*10**18))
        // Values.push(toFixedNumber((parseFloat(allocationlist[0]?.min))*100*10**18))
        console.log("Values", Values);


        // isAuto 0
        //_isvested 1
        // isWithoutToken 2
        // isWhitelisted 3
        // buyType isBNB or not 4
        // isToken isToken or not 5
        // launchpadtype isnormal or fair
        isSetter.push(isPancake);
        isSetter.push(isVested);
        isSetter.push(isWithoutToken);
        isSetter.push(isWhitelisted);
        isSetter.push(currency === "BNB" ? true : false)
        isSetter.push(isToken)
        isSetter.push(LaunchpadType)
        // isSetter.push(this.state.isAffiliate);
        console.log("isSetter", isSetter);

        // description 0 
        // website,twitter,telegram 1,2,3
        //logo 4
        //name 5
        //symbol 6
        // githup 7
        // instagram 8
        // whatsapp 9
        // linkedin 10
        // facebook 11
        //tier type 12
        Details.push(description)
        Details.push(website);
        Details.push(twitter);
        Details.push(telegram);
        Details.push(logo);
        Details.push(tokeninfo?.name);
        Details.push(tokeninfo?.symbol);
        Details.push(github);
        Details.push(instagram);
        Details.push(discord);
        Details.push(reddit);
        Details.push(youtube);
        Details.push(banner);
        Details.push(saletype);
        // console.log("Details", Details);


        //  const data = this.validate()
        //  if(data)
        //      return false;


        console.log("DATA : ", Addresses, Values, isSetter, Details)
        const sale = await createNativePresale(Addresses, Values, isSetter, Details, account)
        console.log("creating____sale", sale)
        console.log("saleDATA : ", Addresses, Values, isSetter, Details)
        if(sale && sale != "0x0000000000000000000000000000000000000000"){
            setCreatedSale(sale);
            // setIsDeposited(true);
        }
        // setCreatedSale(sale);

    }



    const goToSale = () => {
        console.log("goToSale")
        // window.onbeforeunload = undefined
        // window.onpopstate = undefined
        window.location.href = `${window.location.origin}/native-launchpad-detail/${createdSale}`;
    }


    return (
        <>
            <Container fluid className='common_bg position-relative'>
                <div className='liner'></div>
                <Row>
                    <Col xl={2} lg={0} className='p-0 d-none d-xl-block'>
                        <Sidebar />
                    </Col>
                    <Col xl={10} lg={12}>
                        <Header title={'Create Launchpad'} />
                        <div className='mt-5 profile_holder ps-lg-3'>
                            {/* Wizard */}
                            <div className='d-none d-xl-block container px-1'>
                                <div className='row mt-5'>
                                    <div className='col-3'>
                                        <div className={currentStep == 1 ? "card_bg_wizard active card" : "card_bg_wizard card"}
                                        >
                                            <div className='card-body'>
                                                <div className='d-flex align-items-center h-100'>
                                                    <div className='wizard_badge_num'>
                                                        1
                                                    </div>
                                                    <div className='ps-3 create_wiz_desc'>
                                                        <h5>Verify Token</h5>
                                                        <p className='mb-0'>Enter the token address and verify</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {currentStep == 1 ?
                                        <div className='col-3'>
                                            <div className={currentStep == 2 ? "card_bg_wizard active card" : "card_bg_wizard card"}
                                            //    onClick={()=>{this.setState({ currentStep: 2})}}
                                            >
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center h-100'>
                                                        <div className='wizard_badge_num'>
                                                            2
                                                        </div>
                                                        <div className='ps-3 create_wiz_desc'>
                                                            <h5>Defi Launchpad Info</h5>
                                                            <p className='mb-0'>Enter the launchpad information that you want to raise, that should be enter all details about your presale</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> : <div className='col-3'>
                                            <div className={currentStep == 2 ? "card_bg_wizard active card" : "card_bg_wizard card"}>
                                                <div className='card-body'>
                                                    <div className='d-flex align-items-center h-100'>
                                                        <div className='wizard_badge_num'>
                                                            2
                                                        </div>
                                                        <div className='ps-3 create_wiz_desc'>
                                                            <h5>Defi Launchpad Info</h5>
                                                            <p className='mb-0'>Enter the launchpad information that you want to raise, that should be enter all details about your presale</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    <div className='col-3'>
                                        <div className={currentStep == 3 ? "card_bg_wizard active card" : "card_bg_wizard card"}
                                        //    onClick={()=>{this.setState({ currentStep: 3})}}
                                        >
                                            <div className='card-body'>
                                                <div className='d-flex align-items-center h-100'>
                                                    <div className='wizard_badge_num'>
                                                        3
                                                    </div>
                                                    <div className='ps-3 create_wiz_desc'>
                                                        <h5>Add Additional Info</h5>
                                                        <p className='mb-0'>Let people know who you are</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-3'>
                                        <div className={currentStep == 4 ? "card_bg_wizard active card" : "card_bg_wizard card"}
                                        //    onClick={()=>{this.setState({ currentStep: 4})}}
                                        >
                                            <div className='card-body'>
                                                <div className='d-flex align-items-center h-100'>
                                                    <div className='wizard_badge_num'>
                                                        4
                                                    </div>
                                                    <div className='ps-3 create_wiz_desc'>
                                                        <h5>Finish</h5>
                                                        <p className='mb-0'>Review your information</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* end Wizard */}
                            {/* ongoing_sec */}
                            <div className='ongoing_sec form_sec'>


                                <div className="inner_bg mt-5">
                                    <div className='row'>
                                        <div className='col-12 col-md-10 col-lg-9 mx-auto'>
                                            <div className="tab_img">
                                                <div className='card_bg card bg_card_robot_bottom mb-4'>
                                                    <div className='card-body'>
                                                        {!isstepcompleted &&
                                                            <div className='row align-items-center pb-4'>
                                                                <div className='col-12 col-md-6 mb-3 mb-md-0'>
                                                                    <p className='bottom_text mb-0'>(*) is required field.</p>

                                                                </div>

                                                            </div>}

                                                        <div id="firststep" className={currentStep == 1 ? "d-block" : "d-none"}>
                                                            {
                                                                !isstepcompleted ?
                                                                    <div className='row'>
                                                                        <div className='col-12 col-md-12'>
                                                                            <p className='input_desc_sm'>Token Address*</p>
                                                                            <div className="inputs input-groups">
                                                                                <InputGroup className="">
                                                                                    <FormControl
                                                                                        id="tokenaddress" placeholder=""
                                                                                        aria-describedby="basic-addon2"
                                                                                        value={tokenaddress}
                                                                                        onChange={(e) => settokenaddress(e.target.value)}
                                                                                    />

                                                                                </InputGroup>
                                                                                <span className="text-danger f-12 d-block text-left">{errors.tokenaddress}</span>
                                                                            </div>
                                                                            <div className='note_desc mt-1 mb-1'>
                                                                                <p>Pool creation fee: {poolfee}% {CHAINS[getChainId()].SYMBOL}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className={isEmpty(tokeninfo) ? "d-none" : 'col-12 col-md-12 mb-3'}>
                                                                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                                <span className='desc_grey_txt'>Name :</span>
                                                                                <span className='desc_grey_txt font_12'>{tokeninfo?.name}</span>
                                                                            </p>


                                                                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                                <span className='desc_grey_txt'>Symbol :</span>
                                                                                <span className='desc_grey_txt font_12'>{tokeninfo?.symbol}</span>
                                                                            </p>

                                                                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                                <span className='desc_grey_txt'>Decimals :</span>
                                                                                <span className='desc_grey_txt font_12'>{parseFloat(tokeninfo?.decimals)}</span>
                                                                            </p>
                                                                        </div>

                                                                        <div className='col-12 col-md-12 mb-1'>
                                                                            <p className='input_desc_sm'>Currency</p>
                                                                            <div className='d-flex'>
                                                                                {/* {networkSwitch && <div className={currency == CHAINS[getChainId()].SYMBOL ? 'currency_badge active me-2' : 'currency_badge me-2'}
                                                                                    value={CHAINS[getChainId()].SYMBOL}
                                                                                    onClick={(e) => {
                                                                                        console.log(e.target.getAttribute("value"));
                                                                                        setCurrency(e.target.getAttribute("value"))
                                                                                        GetTokenAddress(e.target.getAttribute("value"))
                                                                                    }}
                                                                                >{CHAINS[getChainId()].SYMBOL}</div>}
                                                                                <div className={currency == 'DAI' ? 'currency_badge active me-2' : 'currency_badge me-2'}
                                                                                    value="DAI"
                                                                                    onClick={(e) => {
                                                                                        console.log(e.target.getAttribute("value"));
                                                                                        setCurrency(e.target.getAttribute("value"))
                                                                                        GetTokenAddress(e.target.getAttribute("value"))
                                                                                    }}
                                                                                >DAI</div> */}

                                                                                <div className={currency == 'USDT' ? 'currency_badge active me-2' : 'currency_badge me-2'} value="USDT"
                                                                                    // onClick={(e) => {
                                                                                    //     console.log(e.target.getAttribute("value"));
                                                                                    //     setCurrency(e.target.getAttribute("value"))
                                                                                    //     GetTokenAddress(e.target.getAttribute("value"))
                                                                                    // }}
                                                                                >USDT</div>
                                                                                {/* <div className={currency == 'USDC' ? 'currency_badge active me-2' : 'currency_badge me-2'} value="USDC"
                                                                                    onClick={(e) => {
                                                                                        console.log(e.target.getAttribute("value"));
                                                                                        setCurrency(e.target.getAttribute("value"))
                                                                                        GetTokenAddress(e.target.getAttribute("value"))
                                                                                    }}
                                                                                >USDC</div> */}

                                                                            </div>
                                                                            <div className='note_desc mt-1 mb-1'>
                                                                                <p>Users will pay with {currency} for your token</p>
                                                                            </div>

                                                                            <div className='col-12 col-md-12'>
                                                                            <p className='input_desc_sm'>Sale Type*</p>
                                                                            <div className="inputs input-groups">
                                                                                <InputGroup className="">
                                                                                    <FormControl
                                                                                        id="tokenaddress" placeholder=""
                                                                                        aria-describedby="basic-addon2"
                                                                                        value={saletype}
                                                                                        onChange={(e) => {
                                                                                            setSaletype(e.target.value)
                                                                                            if(e?.target?.value?.length > 0){
                                                                                                setErrors({...errors , ["saletype"] : ""})
                                                                                            }
                                                                                            else{
                                                                                                setErrors({...errors , ["saletype"] : "Field is required"})
                                                                                            }
                                                                                        }}
                                                                                    />

                                                                                </InputGroup>
                                                                                <span className="text-danger f-12 d-block text-left">{errors.saletype}</span>
                                                                            </div>
                                                                            {/* <div className='note_desc mt-1 mb-1'>
                                                                                <p>Pool creation fee: {poolfee}% {CHAINS[getChainId()].SYMBOL}</p>
                                                                            </div> */}
                                                                        </div>

                                                                        </div>

                                                                        <div className='col-12 col-md-12 mb-3'>
                                                                            {/* <p className='input_desc_sm'>Fee Options</p> */}
                                                                            {networkSwitch && getFee && getFee.map((data, index) => {
                                                                                console.log("map", data, index)
                                                                                let isCurrency = data.currencyvalue > 0 ? true : false
                                                                                let isToken = data.tokenvalue > 0 ? true : false
                                                                                let i = index
                                                                                return (
                                                                                    <>
                                                                                        <div className="custom-control custom-radio mb-2">
                                                                                            <div onClick={() => {
                                                                                                console.log("valsdgfsdsdgsd", data, 5, i);
                                                                                                setCheckedInd(i)
                                                                                            }}>

                                                                                                <input type="checkbox" id="customRadio" name={`customRadio${i}`} className="custom-control-input"
                                                                                                    checked={CheckedInd == i ? true : false}
                                                                                                />
                                                                                                {console.log("tokenFee+bnbFee", tokenFee, currencyFee)}
                                                                                                <label className="custom-control-label" for={`customRadio${i}`}>

                                                                                                    {isCurrency ? `${data.currencyvalue}% ${currency} raised` : ""}
                                                                                                    {isCurrency && isToken ? "+" : ''} {isToken ? `${data.tokenvalue}% token sold` : ''}
                                                                                                    <span>{isCurrency && !isToken ? "(recommended)" : ''}</span></label>
                                                                                            </div>

                                                                                        </div>

                                                                                    </>
                                                                                )
                                                                            })
                                                                            }


                                                                            {/* <div className="custom-control custom-radio mb-2">
                                                                        <input type="checkbox" id="customRadio1" name="customRadio" className="custom-control-input" value={5}
                                                                        // checked


                                                                        />
                                                                        <label className="custom-control-label" for="customRadio1">1% USDC raised only <span>(recommended)</span></label>
                                                                    </div>

                                                                    <div className="custom-control custom-radio">
                                                                        <input type="checkbox" id="customRadio2" name="customRadio" value={2} className="custom-control-input"


                                                                        />
                                                                        <label className="custom-control-label" for="customRadio2">{2}% USDC raised + 2% token sold</label>
                                                                    </div> */}
                                                                        </div>
                                                                        {/* <div className='col-12 col-md-12 mb-3'>
                                                                        <p className='input_desc_sm'>Tier Type</p>
                                                                    <Dropdown className='tierdrop'>
                                                                            <DropdownToggle className='text-start w-100'>{selectedallocation}</DropdownToggle>
                                                                            <DropdownMenu className='w-100'>
                                                                            {allocationlist?.map((data , i) =>  <DropdownItem><p onClick={() => {setSelectedallocation(data?.name) ; setSelectedtire(data?.min)}}>{data?.name}</p></DropdownItem>)}
                                                                            </DropdownMenu>
                                                                        </Dropdown>
                                                                            </div> */}

                                                                        {currency != "ETH" ?
                                                                            <div className='col-12 col-md-12 mb-4 mt-4'>
                                                                                <div className='card_footer_form'>
                                                                                    <div className='d-flex align-items-center justify-content-center'>
                                                                                        <i class="fa fa-exclamation-circle text-danger-war" aria-hidden="true"></i>
                                                                                        <p className='mb-0 ps-3'>Do not use this currency for auto liquidity tokens, or tokens that depend on WETH pair. It will lead to error when finalizing the pool or transferring the tokens (for example Liquidity Generator Token, BabyToken, BuyBack Baby Token) <br />
                                                                                            Contact Space Launch for more information.</p>
                                                                                    </div>

                                                                                </div>
                                                                            </div> : <></>}

                                                                        <div className='col-12 col-md-12 mb-4'>
                                                                            <div className='card_footer_form'>
                                                                                <p className='mb-0'>For auto listing, after you finalize the pool your token will be auto listed on DEX.</p>
                                                                            </div>
                                                                        </div>


                                                                        <div className='col-12 col-md-12 text-center'>
                                                                            {/* {this.state.step1 ?  */}
                                                                            {approvebutton &&
                                                                                <button className="orange_small_primary " onClick={() => {
                                                                                    setCurrentstep(2);
                                                                                    // setIsstepcompleted(true);
                                                                                }}
                                                                                    disabled={(button1 || saletype?.length <= 0)} >
                                                                                    Next
                                                                                </button>
                                                                            }

                                                                            {!approvebutton &&
                                                                                <button className="orange_small_primary " onClick={() => {
                                                                                    approvetoken();
                                                                                    // setIsstepcompleted(true); 
                                                                                }}
                                                                                    disabled={button1}
                                                                                >
                                                                                    Approve
                                                                                </button>
                                                                            }


                                                                        </div>

                                                                    </div>
                                                                    :
                                                                    // <div className='row pb-4'>



                                                                    //     <div className='col-12 col-md-12 mb-4 pt-4'>
                                                                    //         <div className='card_footer_form'>
                                                                    //             <p className='mb-0'>Please deploy your launchpad receipt</p>
                                                                    //         </div>
                                                                    //     </div>


                                                                    //     <div className='col-12 col-md-12 text-center'>
                                                                    //         {/* {this.state.step1 ?  */}

                                                                    //         <button className="orange_small_primary "
                                                                    //          onClick={async() =>{ 
                                                                    //             let result = await DeployLaunchReceiptToken()
                                                                    //             console.log(result,'DeployLaunchReceiptToken')
                                                                    //             if(result){
                                                                    //                 setLaunchReceipt(result?.logs[0]?.address)
                                                                    //                 setCurrentstep(2)
                                                                    //             }
                                                                    //         }}
                                                                    //             disabled={button1} >
                                                                    //             Deploy
                                                                    //         </button>

                                                                    //         {/* setCurrentstep(2) */}

                                                                    //     </div>

                                                                    // </div>
                                                                    <></>

                                                            }

                                                        </div>




                                                        <div id="secondstep" className={currentStep == 2 ? "d-block" : "d-none"}>
                                                            <div className='row'>

                                                                <div className='col-12 col-md-12 mb-3'>

                                                                    <p className='input_desc_sm'>Token price</p>
                                                                    <div className="inputs input-groups">
                                                                        <InputGroup className="">
                                                                            <FormControl pattern="^[1-9]+[0-9]*$" id="tokenprice" value={tokenprice} onChange={(e) => {

                                                                                setTokenprice(e.target.value);
                                                                                var rx = new RegExp(/^[-+]?[0-9]+\.[0-9]+$/)
                                                                                var rd = new RegExp(/^\d+$/);
                                                                                if (!e.target.value || isNaN(e.target.value) || e.target.value == 0) {
                                                                                    const formvalue = { ...errors, ["tokenprice"]: "Invalid Total presale" }
                                                                                    setErrors(formvalue)
                                                                                }
                                                                                else
                                                                                    if (rx.test(e.target.value) || rd.test(e?.target?.value) || !isNaN(e.target.value)) {
                                                                                        const formvalue = { ...errors, ["tokenprice"]: "" }
                                                                                        setErrors(formvalue)
                                                                                    }
                                                                                    else {
                                                                                        const formvalue = { ...errors, ["tokenprice"]: "Invalid presale" }
                                                                                        setErrors(formvalue)
                                                                                    }


                                                                                handledisable();
                                                                            }} placeholder=""
                                                                                aria-describedby="basic-addon2"
                                                                            />
                                                                        </InputGroup>
                                                                        {/* <span className="text-danger f-12 d-block text-left">{errors.hardcap}</span> */}
                                                                        <span className="text-danger f-12 d-block text-left">{errors?.tokenprice}</span>
                                                                    </div>
                                                                </div>

                                                                {/* <div className='col-12 col-md-12 mb-0'>
                                                                    <p className='input_desc_sm'>Soft Cap*</p>
                                                                    <div className="inputs input-groups">
                                                                        <InputGroup className="">
                                                                            <FormControl id="softcap" value={softcap} 
                                                                            readOnly = {tokenprice? false : true}
                                                                            onChange={(e) => {
                                                                                setSoftcap(e.target.value);
                                                                                const formvalue = { ...errors, ["softcap"]: "" }
                                                                                let Errors = errors
                                                                                setErrors(formvalue);
                                                                                var rx = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;

                                                                                if (!rx.test(parseFloat(e.target.value))) {
                                                                                    Errors = { ...Errors, ["softcap"]: "Invalid softcap !" }
                                                                                    setErrors(Errors);
                                                                                }
                                                                                else if (isNaN(e.target.value)) {
                                                                                    Errors = { ...Errors, ["softcap"]: "Invalid softcap" }
                                                                                    setErrors(Errors);
                                                                                }
                                                                                else if (!e.target.value) {
                                                                                    Errors = { ...Errors, ["softcap"]: "Softcap field is required" }
                                                                                    setErrors(Errors);
                                                                                }
                                                                                else  if(hardcap){
                                                                                    if(e.target?.value < (parseFloat(hardcap)/2)){console.log("Softcap must");
                                                                                    Errors = { ...Errors, ["softcap"]: "Softcap must be greater than 50% of Hardcap" }
                                                                                    setErrors(Errors);
                                                                                        
                                                                                    }
                                                                                    else if(e.target?.value >= parseFloat(hardcap)/2){
                                                                                        Errors = { ...Errors, ["softcap"]: "" , ["hardcap"]: ""  }
                                                                                        setErrors(Errors);
                                                                                    }
                                                                                    else{
                                                                                        Errors = { ...Errors, ["softcap"]: "" }
                                                                                        setErrors(Errors);
                                                                                    }
                                                                                  }
                                                                                  else{
                                                                                    Errors = { ...Errors, ["softcap"]: "" }
                                                                                    setErrors(Errors);
                                                                                  }
                                                                                
                                                                            }} placeholder=""
                                                                                aria-describedby="basic-addon2"

                                                                            />

                                                                        </InputGroup>
                                                                        <span className="text-danger f-12 d-block text-left">{errors.softcap}</span>
                                                                    </div>
                                                                    <div className='note_desc mt-1 mb-0'>
                                                                    </div>
                                                                </div> */}

                                                                <div className='col-12 col-md-12 mb-3'>
                                                                    <p className='input_desc_sm'>Hard Cap*</p>
                                                                    <div className="inputs input-groups">
                                                                        <InputGroup className="">
                                                                            <FormControl id="hardcap" value={hardcap} 
                                                                            readOnly = {tokenprice? false : true}
                                                                            onChange={(e) => {
                                                                                setHardcap(e.target.value);
                                                                                // setPresaleRate(parseFloat(e?.target?.value) * parseFloat(tokenprice))
                                                                                const formvalue = { ...errors, ["hardcap"]: "" }
                                                                                let Errors = errors
                                                                                setErrors(formvalue);

                                                                                var rd = new RegExp(/^\d+((.)|(.\d{0,4})?)$/);
                                                                                console.log("!rd.test(parseFloat(e.target.value))", !rd.test(parseFloat(e.target.value)))
                                                                                if (!rd.test(parseFloat(e.target.value))) {

                                                                                    Errors = { ...Errors, ["hardcap"]: "Invalid Hardcap" }
                                                                                    setErrors(Errors);
                                                                                }
                                                                                else if (isNaN(e.target.value)) {
                                                                                    Errors = { ...Errors, ["hardcap"]: "Invalid Hardcap" }
                                                                                    setErrors(Errors);
                                                                                }
                                                                                else if (!e.target.value || e.target.value == 0) {
                                                                                    Errors = { ...Errors, ["hardcap"]: "Hardcap field is required" }
                                                                                    setErrors(Errors);
                                                                                }
                                                                                else if (parseFloat(e.target.value) < parseFloat(softcap)) {
                                                                                    Errors = { ...Errors, ["hardcap"]: "Hardcap must be greater than the softcap" }
                                                                                    setErrors(Errors);
                                                                                }
                                                                                else {
                                                                                    Errors = { ...Errors, ["hardcap"]: "" }
                                                                                    setErrors(Errors);
                                                                                }

                                                                                if (parseFloat(softcap) < (parseFloat(e.target.value) / 2)) {
                                                                                    Errors = { ...Errors, ["softcap"]: "Softcap must be less than 50% of Hardcap" }
                                                                                    setErrors(Errors);
                                                                                }
                                                                                else if (parseFloat(softcap) <= (parseFloat(e.target.value))) {
                                                                                    Errors = { ...Errors, ["softcap"]: "" }
                                                                                    setErrors(Errors);
                                                                                }


                                                                            }} placeholder=""
                                                                                aria-describedby="basic-addon2"
                                                                            />

                                                                        </InputGroup>
                                                                        <span className="text-danger f-12 d-block text-left">{errors.hardcap}</span>
                                                                    </div>
                                                                </div>






                                                                


                                                                {/* <div className='col-12 col-md-12 mb-3'>
                                                                    <p className='input_desc_sm'>Whitelist</p>

                                                                    <div className="custom-control custom-radio mb-2">
                                                                        <input type="radio"
                                                                            checked={!isWhitelisted ? true : false}
                                                                            onChange={(e) => setIsWhitelisted(false)}
                                                                            id="customRadio3" name="customRadio" className="custom-control-input" />
                                                                        <label className="custom-control-label" for="customRadio3">Disable</label>
                                                                    </div>

                                                                    <div className="custom-control custom-radio">
                                                                        <input type="radio"
                                                                            checked={isWhitelisted ? true : false}
                                                                            onChange={(e) => setIsWhitelisted(true)}
                                                                            id="customRadio4" name="customRadio" className="custom-control-input" />
                                                                        <label className="custom-control-label" for="customRadio4">Enable</label>
                                                                    </div>
                                                                </div> */}

                                                                

                                                                <div className='col-12 col-md-12 mb-3'>
                                                                    <p className='input_desc_sm'>Minimum Buy *</p>
                                                                    <div className="inputs input-groups">
                                                                        <InputGroup className="">
                                                                            <FormControl id="mincontribution"
                                                                                value={min}
                                                                                placeholder=""
                                                                                aria-describedby="basic-addon2"
                                                                                onChange={(e)=>{
                                                                                    const formvalue = { ...errors, ["min"]: "" }
                                                                                    setMin(e?.target?.value);
                                                                                    let Errors = errors
                                                                                    setErrors(formvalue);
                                                                                    if(isNaN(e.target.value)){
                                                                                        
                                                                                        Errors = { ...Errors, ["min"]: "Invalid Minimum Buy " }
                                                                                        setErrors(Errors);
                                                                                    }
                                                                                    if(!e.target.value){
                                                                                        Errors = { ...Errors, ["min"]: "Minimum Buy field is required" }
                                                                                        setErrors(Errors);
                                                                                    }
                                                                                   if(max){
                                                                                    if(e.target.value > max){
                                                                                        Errors = { ...Errors, ["min"]: "Minimum Buy  must be < Maximum Buy" }
                                                                                        setErrors(Errors);
                                                                                    }
                                                                                    else{
                                                                                        Errors = { ...Errors, ["min"]: "" }
                                                                                        setErrors(Errors);
                                                                                    }
                                                                                   }
                                                                                } }
                                                                            />

                                                                        </InputGroup>
                                                                        <span className="text-danger f-12 d-block text-left">{errors?.min}</span>
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 col-md-12 mb-3'>
                                                                    <p className='input_desc_sm'>Maximum Buy *</p>
                                                                    <div className="inputs input-groups">
                                                                        <InputGroup className="">
                                                                            <FormControl id="maxcontribution"
                                                                                value={max}
                                                                                placeholder=""
                                                                                aria-describedby="basic-addon2"
                                                                                onChange={(e)=>{
                                                                                    const formvalue = { ...errors, ["max"]: "" }
                                                                                    setMax(e?.target?.value);
                                                                                    let Errors = errors
                                                                                    setErrors(formvalue);
                                                                                    if(isNaN(e.target.value)){
                                                                                        Errors = { ...Errors, ["max"]: "Invalid Maximum Buy " }
                                                                                        setErrors(Errors);
                                                                                    }
                                                                                    if(!e.target.value){
                                                                                        Errors = { ...Errors, ["max"]: "Maximum Buy field is required" }
                                                                                        setErrors(Errors);
                                                                                    }
                                                                                    if(parseFloat(min)){
                                                                                        if(e.target.value < parseFloat(min)){
                                                                                            Errors = { ...Errors, ["max"]: "Maximum Buy  must be > Minimum Buy" }
                                                                                            setErrors(Errors);
                                                                                        }
                                                                                        else{
                                                                                            Errors = { ...Errors, ["max"]: "" }
                                                                                            setErrors(Errors);
                                                                                        }
                                                                                       }
                                                                                } }
                                                                            />

                                                                        </InputGroup>
                                                                        <span className="text-danger f-12 d-block text-left">{errors?.max}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className='col-12 col-md-12 mb-3'>
                                                                <p className='input_desc_sm'>Listing Options</p>
                                                                <div className='d-flex'>
                                                                    <div className='currency_badge  me-2 active' onClick={(e) =>
                                                                        setIsPancake(true)}>Auto</div>
                                                                    <div className='currency_badge  me-2' onClick={(e) => setIsPancake(false)}>Manual</div>
                                                                </div>

                                                            </div> */}

                                                            {isPancake ?
                                                                <>
                                                                    {/* <div className='col-12 col-md-12 mb-3'>
                                                                        <p className='input_desc_sm'>Locking Days</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl id="liquidity" value={unlockOn} onChange={(e) => {
                                                                                    setUnlockOn(e.target.value);
                                                                                    var rx = new RegExp(/^\d+$/);
                                                                                    if (!rx.test(e.target.value)) {
                                                                                        const formvalue = { ...errors, ["lockingday"]: "Invalid Locking Days !" };
                                                                                        setErrors(formvalue);
                                                                                    }
                                                                                    else {
                                                                                        const formvalue = { ...errors, ["lockingday"]: "" };
                                                                                        setErrors(formvalue);
                                                                                    }
                                                                                    handledisable();
                                                                                }} placeholder="0" aria-describedby="basic-addon2" />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{errors.lockingday}</span>
                                                                        </div>
                                                                    </div> */}



                                                                    {/* <div className='col-12 col-md-12 mb-0'>
                                                                        <p className='input_desc_sm'>Listing Rate per BNB</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl id="maxcontribution"
                                                                                    placeholder="0" aria-describedby="basic-addon2" 
                                                                                    value={listingrate}
                                                                                    onChange={(e)=>{
                                                                                        const formvalue = { ...errors, ["listingrate"]: "" }
                                                                                        setListingrate(e?.target?.value);
                                                                                    let Errors = errors
                                                                                    setErrors(formvalue);
                                                                                        if(!isFloat(e.target.value)){  
                                                                                            var rx = new RegExp(/^\d+$/);
                                                                                        if(!rx.test(e.target.value)){
                                                                                            Errors = { ...errors, ["listingrate"]: `Invalid Listing Rate ` };
                                                                                            setErrors(formvalue);
                                                                                        }
                                                                                            else {
                                                                                                Errors = { ...errors, ["listingrate"]: `` };
                                                                                            setErrors(formvalue);
                                                                                            }
                                                                                        }
                                                                                        }}
                                                                                    
                                                                                    />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{errors?.listingrate}</span>
                                                                        </div>
                                                                        <div className='note_desc mt-1 mb-1'>
                                                                            <p>1 BNB = 2 BTC</p>
                                                                        </div>
                                                                    </div> */}


                                                                    {/* <div className='col-12 col-md-12 mb-3'>
                                                                        <p className='input_desc_sm'>Expolre launchpad</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl id="liquidity" value={liquidityPercent} onChange={(e) => {
                                                                                    if (!isFloat(e.target.value)) {
                                                                                        setLiquidityPercent(e.target.value);
                                                                                        if (isNaN(e.target.value) || e.target.value < 51 || e.target.value > 100) {
                                                                                            const formvalue = { ...errors, ["liquidity"]: "Invalid KittySwap Liquidity !" };
                                                                                            setErrors(formvalue);
                                                                                        }
                                                                                        else {
                                                                                            const formvalue = { ...errors, ["liquidity"]: "" };
                                                                                            setErrors(formvalue);
                                                                                        }
                                                                                    }
                                                                                    handledisable();
                                                                                }}
                                                                                    placeholder="0" aria-describedby="basic-addon2" />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{errors.liquidity}</span>
                                                                        </div>
                                                                    </div> */}
                                                                </> : <></>}

                                                            {/* {isPancake ?
                                                                <div className='col-12 col-md-12 mb-2'>
                                                                    <div className='note_desc mt-1 mb-0'>
                                                                        <p>Enter the percentage of raised funds that sholud be allocated to Liquidity on (Min 51%, Max 100%)</p>
                                                                        <p>If I spend 1 {currency} on how many tokens will I receive? Usually the amount is lower than presale rate to allow for a higher listing price on</p>

                                                                    </div>
                                                                </div> : <></>} */}

                                                            <div className='col-12 col-md-12 mb-0'>
                                                                <p className='input_desc_sm'>Select Start time & End time (UTC)*</p>
                                                            </div>

                                                            <div className='col-12 col-md-12 mb-3'>
                                                                <p className='input_desc_sm'>Start Time (UTC)*</p>
                                                                <div className="inputs input-groups date_inoput_grps">
                                                                    <InputGroup className="datepicker_input">

                                                                        <DatePicker
                                                                            minDate={new Date()}
                                                                            filterTime={filterPassedTime()}
                                                                            showTimeSelect
                                                                            selected={startDate}
                                                                            onChange={(date) => {
                                                                                setStartDate(date)
                                                                                if (new Date(date).getTime() >= new Date().getTime()) {
                                                                                    let formData = { ...errors, ["startDate"]: "" };
                                                                                    setErrors(formData);
                                                                                }
                                                                                else {
                                                                                    let formData = { ...errors, ["startDate"]: "Start Time needs to be after now" };
                                                                                    setErrors(formData);
                                                                                }
                                                                                if (endDate) {
                                                                                    if (Date.parse(date) > endDate) {
                                                                                        let formData = { ...errors, ["startDate"]: "Start Time must be < End Time" };
                                                                                        setErrors(formData);
                                                                                    }
                                                                                    if (endDate > Date.parse(date)) {
                                                                                        let formData = { ...errors, ["endDate"]: "" };
                                                                                        setErrors(formData);
                                                                                    }

                                                                                }
                                                                                handledisable();
                                                                            }
                                                                            }
                                                                            onKeyDown={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                                        />

                                                                    </InputGroup>
                                                                    {/* <div className='cur_pointer'>
                    <button variant="outline-secondary" className="trans_cal_btn">
                        <i class="far fa-calendar-alt"></i>
                    </button>
                </div> */}
                                                                </div>
                                                                <span className="text-danger f-12 d-block text-left">{errors.startDate}</span>
                                                            </div>
                                                            <div className='col-12 col-md-12 mb-3'>
                                                                <p className='input_desc_sm'>End Time (UTC)*</p>
                                                                <div className="inputs input-groups date_inoput_grps">
                                                                    <InputGroup className="datepicker_input" >
                                                                        <DatePicker
                                                                            minDate={new Date()}
                                                                            filterTime={filterPassedTime()}
                                                                            showTimeSelect
                                                                            selected={endDate}
                                                                            onChange={(date) => {
                                                                                setEndDate(date);

                                                                                if (Date.parse(date) > Date.now()) {
                                                                                    let formData = { ...errors, ["endDate"]: "" };
                                                                                    setErrors(formData);
                                                                                }
                                                                                else {
                                                                                    let formData = { ...errors, ["endDate"]: "End Time needs to be after now" };
                                                                                    setErrors(formData);
                                                                                }
                                                                                if (startDate) {
                                                                                    if (Date.parse(date) <= startDate) {
                                                                                        console.log("invalideee");
                                                                                        let formData = { ...errors, ["endDate"]: "End Time must be > Start Time" };
                                                                                        setErrors(formData);
                                                                                        console.log("invalideee", formData);

                                                                                    }
                                                                                    if (startDate < Date.parse(date)) {
                                                                                        let formData = { ...errors, ["startDate"]: "" };
                                                                                        setErrors(formData);
                                                                                        let formdata = { ...errors, ["endDate"]: "" };
                                                                                        setErrors(formdata);
                                                                                    }
                                                                                }
                                                                                handledisable();

                                                                            }}
                                                                            onKeyDown={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                            dateFormat="MMMM d, yyyy h:mm aa"
                                                                        />

                                                                    </InputGroup>
                                                                    {/* <div className='cur_pointer'>
                    <button variant="outline-secondary" className="trans_cal_btn">
                        <i class="far fa-calendar-alt"></i>
                    </button>
                </div> */}
                                                                </div>
                                                                <span className="text-danger f-12 d-block text-left">{errors.endDate}</span>
                                                            </div>

                                                            <div className='col-12 col-md-12'>
                                                                <div className="custom-control custom-checkbox">
                                                                    <input type="checkbox" checked={isVested}
                                                                        onChange={(e) => setIsVested(e.target.checked)} className="custom-control-input" id="customCheck1" />
                                                                    <label className="custom-control-label" for="customCheck1">Using Vesting Contributor?</label>
                                                                </div></div>

                                                            {isVested ?
                                                                <>
                                                                    <div className='col-12 col-md-12 mb-3 mt-4'>
                                                                        <p className='input_desc_sm'>Vesting Period Days</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl id="liquiditylockingdays" value={vestInterval} onChange={(e) => {
                                                                                    setVestInterval(e.target.value);
                                                                                    var rx = new RegExp(/^\d+$/);
                                                                                    if (!rx.test(e.target.value)) {
                                                                                        console.log("vestinterval", e.target.value)
                                                                                        const formvalue = { ...errors, ["vestInterval"]: "Invalid Vesting Period Days !" };
                                                                                        setErrors(formvalue);
                                                                                    }
                                                                                    else {
                                                                                        console.log("vestinterval1", e.target.value)
                                                                                        const formvalue = { ...errors, ["vestInterval"]: "" };
                                                                                        setErrors(formvalue);
                                                                                    }
                                                                                    handledisable();
                                                                                }}
                                                                                    placeholder=""
                                                                                    aria-describedby="basic-addon2"
                                                                                />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{errors.vestInterval}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-12 col-md-12 mb-3 mt-4'>
                                                                        <p className='input_desc_sm'>Rewards % per Vesting Period</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl id="liquiditylockingdays" value={vestPercent} onChange={(e) => {
                                                                                    console.log("vest_change", e.target.value)
                                                                                    if (!isFloat(e.target.value)) {
                                                                                        console.log("vest_change1", e.target.value, isNaN(e.target.value))
                                                                                        setVestPercent(e.target.value);
                                                                                        if (isNaN(e.target.value) || e.target.value == '' || e.target.value < 0 || e.target.value > 100) {
                                                                                            console.log("vest_change2", e.target.value)
                                                                                            const formvalue = { ...errors, ["vestPercent"]: "Invalid Rewards % per Vesting Period !" };
                                                                                            setErrors(formvalue);
                                                                                        }
                                                                                        else {
                                                                                            const formvalue = { ...errors, ["vestPercent"]: "" };
                                                                                            setErrors(formvalue);
                                                                                        }
                                                                                        handledisable();
                                                                                    }
                                                                                }}
                                                                                    placeholder=""
                                                                                    aria-describedby="basic-addon2"
                                                                                />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{errors.vestPercent}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-12 col-md-12 mb-3 mt-4'>
                                                                        <p className='input_desc_sm'>Rewards % for TGE vesting</p>
                                                                        <div className="inputs input-groups">
                                                                            <InputGroup className="">
                                                                                <FormControl id="liquiditylockingdays" value={firstclaim} onChange={(e) => {
                                                                                    console.log("vest_change", e.target.value)
                                                                                    if (!isFloat(e.target.value)) {
                                                                                        console.log("vest_change1", e.target.value, isNaN(e.target.value))
                                                                                        setFirstclaim(e.target.value);
                                                                                        if (isNaN(e.target.value) || e.target.value == '' || e.target.value < 0 || e.target.value > 100) {
                                                                                            console.log("vest_change2", e.target.value)
                                                                                            const formvalue = { ...errors, ["firstclaim"]: "Invalid Rewards % for initial vesting" };
                                                                                            setErrors(formvalue);
                                                                                        }
                                                                                        else {
                                                                                            const formvalue = { ...errors, ["firstclaim"]: "" };
                                                                                            setErrors(formvalue);
                                                                                        }
                                                                                        handledisable();
                                                                                    }
                                                                                }}
                                                                                    placeholder=""
                                                                                    aria-describedby="basic-addon2"
                                                                                />

                                                                            </InputGroup>
                                                                            <span className="text-danger f-12 d-block text-left">{errors.firstclaim}</span>
                                                                        </div>
                                                                    </div>

                                                                </> : <></>}

                                                            <div className='col-12 col-md-12 text-center mt-3'>
                                                                <button className="orange_small_secondary  me-2"
                                                                    onClick={() => setCurrentstep(1)}
                                                                >
                                                                    Back
                                                                </button>
                                                                <button className="orange_small_primary "
                                                                    onClick={() => {
                                                                        validations();
                                                                        if (handledisable()) {

                                                                        }
                                                                        else {
                                                                            setCurrentstep(3)
                                                                        }
                                                                    }}

                                                                >
                                                                    Next
                                                                </button>
                                                            </div>
                                                        </div>


                                                        <div id="thirdstep" className={currentStep == 3 ? "d-block" : "d-none"}>
                                                            <div className='row'>
                                                                <div className='col-12 col-md-12 mb-0'>

                                                                    <p className='input_desc_sm'>Logo URL*</p>
                                                                    <div className="inputs input-groups">
                                                                        <InputGroup className="">
                                                                            <FormControl id="file" value={logo} onChange={(e) => {
                                                                                setLogo(e.target.value);
                                                                                if (urlvalidation(e.target.value)) {
                                                                                    const formvalue = { ...errors, ["logo"]: "" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                else {
                                                                                    const formvalue = { ...errors, ["logo"]: "Invalid Logo Url!" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                handledisableurlbutton({
                                                                                    description: description,
                                                                                    logo: e.target.value,
                                                                                    banner: banner,
                                                                                    website: website,
                                                                                    facebook: facebook,
                                                                                    twitter: twitter,
                                                                                    telegram: telegram,
                                                                                    github: github,
                                                                                    instagram: instagram,
                                                                                    discord: discord,
                                                                                    reddit: reddit,
                                                                                    youtube: youtube
                                                                                });
                                                                            }}
                                                                                placeholder=""
                                                                                aria-describedby="basic-addon2"
                                                                            />

                                                                        </InputGroup>
                                                                        <span className="text-danger f-12 d-block text-left">{errors.logo}</span>
                                                                    </div>
                                                                    <div className='note_desc mt-1 mb-0'>
                                                                        <p>URL must be end with a supported image extension .png, .jpg,</p>
                                                                    </div>
                                                                </div>


                                                                <div className='col-12 col-md-12 mb-0'>

                                                                    <p className='input_desc_sm'>Banner URL*</p>
                                                                    <div className="inputs input-groups">
                                                                        <InputGroup className="">
                                                                            <FormControl id="file" value={banner} onChange={(e) => {
                                                                                setBanner(e.target.value);
                                                                                if (urlvalidation(e.target.value)) {
                                                                                    const formvalue = { ...errors, ["banner"]: "" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                else {
                                                                                    const formvalue = { ...errors, ["banner"]: "Invalid Banner Url!" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                handledisableurlbutton({
                                                                                    description: description,
                                                                                    logo: logo,
                                                                                    banner: e.target.value,
                                                                                    website: website,
                                                                                    facebook: facebook,
                                                                                    twitter: twitter,
                                                                                    telegram: telegram,
                                                                                    github: github,
                                                                                    instagram: instagram,
                                                                                    discord: discord,
                                                                                    reddit: reddit,
                                                                                    youtube: youtube
                                                                                });
                                                                            }} placeholder=""
                                                                                aria-describedby="basic-addon2"
                                                                            />

                                                                        </InputGroup>
                                                                        <span className="text-danger f-12 d-block text-left">{errors?.banner}</span>
                                                                    </div>
                                                                    <div className='note_desc mt-1 mb-0'>
                                                                        <p>URL must be end with a supported image extension .png, .jpg,</p>
                                                                    </div>
                                                                </div>



                                                                <div className='col-12 col-md-12 mb-3'>
                                                                    <p className='input_desc_sm'>Website*</p>
                                                                    <div className="inputs input-groups">
                                                                        <InputGroup className="">
                                                                            <FormControl id="logolink1" value={website} onChange={(e) => {
                                                                                setWebsite(e.target.value);
                                                                                if (urlvalidation(e.target.value)) {
                                                                                    const formvalue = { ...errors, ["website"]: "" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                else {
                                                                                    const formvalue = { ...errors, ["website"]: "Invalid Website Url!" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                handledisableurlbutton({
                                                                                    description: description,
                                                                                    logo: logo,
                                                                                    banner: banner,
                                                                                    website: e.target.value,
                                                                                    facebook: facebook,
                                                                                    twitter: twitter,
                                                                                    telegram: telegram,
                                                                                    github: github,
                                                                                    instagram: instagram,
                                                                                    discord: discord,
                                                                                    reddit: reddit,
                                                                                    youtube: youtube
                                                                                });
                                                                            }} placeholder="https://Spacelaunch.app"
                                                                                aria-describedby="basic-addon2"
                                                                            />

                                                                        </InputGroup>
                                                                        <span className="text-danger f-12 d-block text-left">{errors.website}</span>
                                                                    </div>
                                                                </div>
                                                                <div className='col-12 col-md-12 mb-3'>
                                                                    <p className='input_desc_sm'>Discord</p>
                                                                    <div className="inputs input-groups">
                                                                        <InputGroup className="">
                                                                            <FormControl id="logolinkfb" value={facebook} onChange={(e) => {
                                                                                setFacebook(e.target.value);
                                                                                if (urlvalidation(e.target.value)) {
                                                                                    const formvalue = { ...errors, ["facebook"]: "" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                else {
                                                                                    const formvalue = { ...errors, ["facebook"]: "Invalid Facebook Url!" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                handledisableurlbutton({
                                                                                    description: description,
                                                                                    logo: logo,
                                                                                    banner: banner,
                                                                                    website: website,
                                                                                    facebook: e.target.value,
                                                                                    twitter: twitter,
                                                                                    telegram: telegram,
                                                                                    github: github,
                                                                                    instagram: instagram,
                                                                                    discord: discord,
                                                                                    reddit: reddit,
                                                                                    youtube: youtube
                                                                                });
                                                                            }}
                                                                                placeholder="https://discord.com"
                                                                                aria-describedby="basic-addon2"

                                                                            />

                                                                        </InputGroup>
                                                                        <span className="text-danger f-12 d-block text-left">{errors.facebook}</span>
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 col-md-12 mb-3'>
                                                                    <p className='input_desc_sm'>Twitter</p>
                                                                    <div className="inputs input-groups">
                                                                        <InputGroup className="">
                                                                            <FormControl
                                                                                id="logolink2" value={twitter} onChange={(e) => {
                                                                                    setTwitter(e.target.value);
                                                                                    if (urlvalidation(e.target.value)) {
                                                                                        const formvalue = { ...errors, ["twitter"]: "" };
                                                                                        setErrors(formvalue);
                                                                                    }
                                                                                    else {
                                                                                        const formvalue = { ...errors, ["twitter"]: "Invalid Twitter Url!" };
                                                                                        setErrors(formvalue);
                                                                                    }
                                                                                    handledisableurlbutton({
                                                                                        description: description,
                                                                                        logo: logo,
                                                                                        banner: banner,
                                                                                        website: website,
                                                                                        facebook: facebook,
                                                                                        twitter: e.target.value,
                                                                                        telegram: telegram,
                                                                                        github: github,
                                                                                        instagram: instagram,
                                                                                        discord: discord,
                                                                                        reddit: reddit,
                                                                                        youtube: youtube
                                                                                    });
                                                                                }} placeholder="https://twitter.com"
                                                                                aria-describedby="basic-addon2"
                                                                            />

                                                                        </InputGroup>
                                                                        <span className="text-danger f-12 d-block text-left">{errors.twitter}</span>
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 col-md-12 mb-3'>
                                                                    <p className='input_desc_sm'>Github</p>
                                                                    <div className="inputs input-groups">
                                                                        <InputGroup className="">
                                                                            <FormControl id="logolinkgit" value={github} onChange={(e) => {
                                                                                setGithub(e.target.value)
                                                                                if (urlvalidation(e.target.value)) {
                                                                                    const formvalue = { ...errors, ["githup"]: "" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                else {
                                                                                    const formvalue = { ...errors, ["githup"]: "Invalid Github Url!" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                handledisableurlbutton({
                                                                                    description: description,
                                                                                    logo: logo,
                                                                                    banner: banner,
                                                                                    website: website,
                                                                                    facebook: facebook,
                                                                                    twitter: twitter,
                                                                                    telegram: telegram,
                                                                                    github: e.target.value,
                                                                                    instagram: instagram,
                                                                                    discord: discord,
                                                                                    reddit: reddit,
                                                                                    youtube: youtube
                                                                                });
                                                                            }}
                                                                                placeholder="https://github.com"
                                                                                aria-describedby="basic-addon2"

                                                                            />

                                                                        </InputGroup>
                                                                        <span className="text-danger f-12 d-block text-left">{errors.github}</span>
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 col-md-12 mb-3'>
                                                                    <p className='input_desc_sm'>Telegram</p>
                                                                    <div className="inputs input-groups">
                                                                        <InputGroup className="">
                                                                            <FormControl id="logolink3" value={telegram} onChange={(e) => {
                                                                                setTelegram(e.target.value);
                                                                                if (urlvalidation(e.target.value)) {
                                                                                    const formvalue = { ...errors, ["telegram"]: "" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                else {
                                                                                    const formvalue = { ...errors, ["telegram"]: "Invalid Telegram Url!" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                handledisableurlbutton({
                                                                                    description: description,
                                                                                    logo: logo,
                                                                                    banner: banner,
                                                                                    website: website,
                                                                                    facebook: facebook,
                                                                                    twitter: twitter,
                                                                                    telegram: e.target.value,
                                                                                    github: github,
                                                                                    instagram: instagram,
                                                                                    discord: discord,
                                                                                    reddit: reddit,
                                                                                    youtube: youtube
                                                                                });
                                                                            }}
                                                                                placeholder="https://telegram.com"
                                                                                aria-describedby="basic-addon2"
                                                                            />

                                                                        </InputGroup>
                                                                        <span className="text-danger f-12 d-block text-left">{errors.telegram}</span>
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 col-md-12 mb-3'>
                                                                    <p className='input_desc_sm'>Instagram</p>
                                                                    <div className="inputs input-groups">
                                                                        <InputGroup className="">
                                                                            <FormControl id="logolinkinsta" value={instagram} onChange={(e) => {
                                                                                setInstagram(e.target.value);
                                                                                if (urlvalidation(e.target.value)) {
                                                                                    const formvalue = { ...errors, ["instagram"]: "" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                else {
                                                                                    const formvalue = { ...errors, ["instagram"]: "Invalid Instagram Url!" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                handledisableurlbutton({
                                                                                    description: description,
                                                                                    logo: logo,
                                                                                    banner: banner,
                                                                                    website: website,
                                                                                    facebook: facebook,
                                                                                    twitter: twitter,
                                                                                    telegram: telegram,
                                                                                    github: github,
                                                                                    instagram: e.target.value,
                                                                                    discord: discord,
                                                                                    reddit: reddit,
                                                                                    youtube: youtube
                                                                                });
                                                                            }}
                                                                                placeholder="https://instagram.com"
                                                                                aria-describedby="basic-addon2"

                                                                            />

                                                                        </InputGroup>
                                                                        <span className="text-danger f-12 d-block text-left">{errors.instagram}</span>
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 col-md-12 mb-3'>
                                                                    <p className='input_desc_sm'>Whatsapp</p>
                                                                    <div className="inputs input-groups">
                                                                        <InputGroup className="">
                                                                            <FormControl id="logolinkdiscord" value={discord} onChange={(e) => {
                                                                                setDiscord(e.target.value);
                                                                                if (urlvalidation(e.target.value)) {
                                                                                    const formvalue = { ...errors, ["discord"]: "" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                else {
                                                                                    const formvalue = { ...errors, ["discord"]: "Invalid Discord Url!" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                handledisableurlbutton({
                                                                                    description: description,
                                                                                    logo: logo,
                                                                                    banner: banner,
                                                                                    website: website,
                                                                                    facebook: facebook,
                                                                                    twitter: twitter,
                                                                                    telegram: telegram,
                                                                                    github: github,
                                                                                    instagram: instagram,
                                                                                    discord: e.target.value,
                                                                                    reddit: reddit,
                                                                                    youtube: youtube
                                                                                });
                                                                            }}
                                                                                placeholder="https://whatsapp.com"
                                                                                aria-describedby="basic-addon2"

                                                                            />

                                                                        </InputGroup>
                                                                        <span className="text-danger f-12 d-block text-left">{errors.discord}</span>
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 col-md-12 mb-3'>
                                                                    <p className='input_desc_sm'>Linkedin</p>
                                                                    <div className="inputs input-groups">
                                                                        <InputGroup className="">
                                                                            <FormControl id="logolinkreddit" value={reddit} onChange={(e) => {
                                                                                setReddit(e.target.value);
                                                                                if (urlvalidation(e.target.value)) {
                                                                                    const formvalue = { ...errors, ["reddit"]: "" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                else {
                                                                                    const formvalue = { ...errors, ["reddit"]: "Invalid Reddit Url!" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                handledisableurlbutton({
                                                                                    description: description,
                                                                                    logo: logo,
                                                                                    banner: banner,
                                                                                    website: website,
                                                                                    facebook: facebook,
                                                                                    twitter: twitter,
                                                                                    telegram: telegram,
                                                                                    github: github,
                                                                                    instagram: instagram,
                                                                                    discord: discord,
                                                                                    reddit: e.target.value,
                                                                                    youtube: youtube
                                                                                });
                                                                            }} placeholder="https://linkedin.com"
                                                                                aria-describedby="basic-addon2"

                                                                            />

                                                                        </InputGroup>
                                                                        <span className="text-danger f-12 d-block text-left">{errors.reddit}</span>
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 col-md-12 mb-0'>
                                                                    <p className='input_desc_sm'>Facebook</p>
                                                                    <div className="inputs input-groups">
                                                                        <InputGroup className="">
                                                                            <FormControl id="logolinkyoutube" value={youtube} onChange={(e) => {
                                                                                setYoutube(e.target.value);
                                                                                if (urlvalidation(e.target.value)) {
                                                                                    const formvalue = { ...errors, ["youtube"]: "" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                else {
                                                                                    const formvalue = { ...errors, ["youtube"]: "Invalid Youtube Video Url!" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                handledisableurlbutton({
                                                                                    description: description,
                                                                                    logo: logo,
                                                                                    banner: banner,
                                                                                    website: website,
                                                                                    facebook: facebook,
                                                                                    twitter: twitter,
                                                                                    telegram: telegram,
                                                                                    github: github,
                                                                                    instagram: instagram,
                                                                                    discord: discord,
                                                                                    reddit: reddit,
                                                                                    youtube: e.target.value
                                                                                });
                                                                            }}
                                                                                placeholder="https://facebook.com"
                                                                                aria-describedby="basic-addon2"

                                                                            />

                                                                        </InputGroup>
                                                                        <span className="text-danger f-12 d-block text-left">{errors.youtube}</span>
                                                                    </div>

                                                                    <div className='note_desc mt-1 mb-0'>
                                                                        <p>Input your youtube URL</p>
                                                                    </div>
                                                                </div>

                                                                <div className='col-12 col-md-12 mb-3'>
                                                                    <p className='input_desc_sm'>Description</p>
                                                                    <div className="inputs input-groups text_are_grp">
                                                                        <InputGroup className="" >
                                                                            <textarea id="description" value={description} onChange={(e) => {
                                                                                setDescription(e.target.value);
                                                                                if (!e.target.value) {
                                                                                    const formvalue = { ...errors, ["description"]: "Invalid Description!" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                else {
                                                                                    const formvalue = { ...errors, ["description"]: "" };
                                                                                    setErrors(formvalue);
                                                                                }
                                                                                handledisableurlbutton({
                                                                                    description: e.target.value,
                                                                                    logo: logo,
                                                                                    banner: banner,
                                                                                    website: website,
                                                                                    facebook: facebook,
                                                                                    twitter: twitter,
                                                                                    telegram: telegram,
                                                                                    github: github,
                                                                                    instagram: instagram,
                                                                                    discord: discord,
                                                                                    reddit: reddit,
                                                                                    youtube: youtube
                                                                                });
                                                                            }}
                                                                                rows="3"
                                                                                aria-describedby="basic-addon2"

                                                                            />

                                                                        </InputGroup>
                                                                        <span className="text-danger f-12 d-block text-left">{errors.description}</span>
                                                                    </div>
                                                                </div>




                                                                <div className='col-12 col-md-12 text-center mt-3'>
                                                                    <button className="orange_small_secondary  me-2" onClick={() => setCurrentstep(2)}>
                                                                        Back
                                                                    </button>
                                                                    <button className="orange_small_primary " onClick={() => {
                                                                        setCurrentstep(4)
                                                                        calculateDepositTokens();
                                                                    }}
                                                                        disabled={button3}

                                                                    >
                                                                        Next
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div id="fourthstep" className={currentStep == 4 ? "d-block" : "d-none"}>
                                                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                {/* <span className='desc_grey_txt'>Deposit Token :</span>
            <span className='desc_grey_txt font_12'>{this.state.deposit} BTC</span> */}
                                                            </p>

                                                            {/* <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
            <span className='desc_grey_txt'>Factory Address :</span>
            <span className='desc_grey_txt font_12'>0xf4567uyht8956 
            <i class="fa fa-files-o pl-2 copy_hover" aria-hidden="true"></i>
            </span>
            </p> */}

                                                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                <span className='desc_grey_txt'>Token Name :</span>
                                                                <span className='desc_grey_txt font_12'>{tokeninfo?.name ? tokeninfo?.name : ""}
                                                                </span>
                                                            </p>

                                                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                <span className='desc_grey_txt'>Token Symbol :</span>
                                                                <span className='desc_grey_txt font_12'>{tokeninfo?.symbol ? tokeninfo?.symbol : ""}
                                                                </span>
                                                            </p>

                                                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                <span className='desc_grey_txt'>Token Decimals :</span>
                                                                <span className='desc_grey_txt font_12'>{tokeninfo?.decimals ? parseFloat(tokeninfo?.decimals) : ""}
                                                                </span>
                                                            </p>

                                                            {/* <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                <span className='desc_grey_txt'>Presale Rate :</span>
                                                                <span className='desc_grey_txt font_12'>2 BTC
                                                                </span>
                                                            </p> */}



                                                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                <span className='desc_grey_txt'>Sale Method :</span>
                                                                <span className='desc_grey_txt font_12'>{isWhitelisted === false ? "Public" : "Private"}
                                                                </span>
                                                            </p>

                                                            {/* <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                <span className='desc_grey_txt'>Softcap :</span>
                                                                <span className='desc_grey_txt font_12'>{softcap ? softcap : " "} {currency ? currency : ""}
                                                                </span>
                                                            </p> */}

                                                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                <span className='desc_grey_txt'>Hardcap :</span>
                                                                <span className='desc_grey_txt font_12'>{hardcap ? hardcap : " "} {currency ? currency : ""}
                                                                </span>
                                                            </p>




                                                            {/* <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                <span className='desc_grey_txt'>Minimum Buy :</span>
                                                                <span className='desc_grey_txt font_12'>1 USDC
                                                                </span>
                                                            </p>

                                                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                <span className='desc_grey_txt'>Maximum Buy :</span>
                                                                <span className='desc_grey_txt font_12'>1 USDC
                                                                </span>
                                                            </p> */}

                                                            {isPancake ?

                                                                <>


                                                                    {/* <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                        <span className='desc_grey_txt'>Listing Rate :</span>
                                                                        <span className='desc_grey_txt font_12'>1 BTC
                                                                        </span>
                                                                    </p> */}
                                                                    <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                        <span className='desc_grey_txt'>Liquidity :</span>
                                                                        <span className='desc_grey_txt font_12'>{liquidityPercent}%
                                                                        </span>
                                                                    </p>

                                                                    <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                        <span className='desc_grey_txt'>Liquidity Lockup Time :</span>
                                                                        <span className='desc_grey_txt font_12'>{unlockOn}
                                                                        </span>
                                                                    </p>
                                                                </> : <></>}

                                                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                <span className='desc_grey_txt'>Start Time :</span>
                                                                <span className='desc_grey_txt font_12'>{startDate ? padToDigits(new Date(startDate).toLocaleDateString()) + "  "
                                                                    + padToDigits(new Date(startDate).getHours()) + ":" + padToDigits(new Date(startDate).getMinutes()) + ":"
                                                                    + padToDigits(new Date(startDate).getSeconds()) + "(GMT)" : ""}
                                                                </span>
                                                            </p>

                                                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                <span className='desc_grey_txt'>End Time :</span>
                                                                <span className='desc_grey_txt font_12'>{endDate ? padToDigits(new Date(endDate).toLocaleDateString()) + "  "
                                                                    + padToDigits(new Date(endDate).getHours()) + ":" + padToDigits(new Date(endDate).getMinutes()) + ":"
                                                                    + padToDigits(new Date(endDate).getSeconds()) + "(GMT)" : ""}
                                                                </span>
                                                            </p>



                                                            <p className='white_txt_sm d-flex justify-content-between mt-0 mb-1'>
                                                                <span className='desc_grey_txt'>Website :</span>
                                                                <span className='desc_grey_txt font_12'>
                                                                    <a href={website} target="_blank" className='link_grn_new link_brk_word'>{website ? website : ""}</a>
                                                                </span>
                                                            </p>

                                                            <div className='mb-4 mt-4'>
                                                                <div className='card_footer_form'>
                                                                    <div className='d-flex align-items-center justify-content-center'>
                                                                        <i class="fa fa-exclamation-circle text-danger-war" aria-hidden="true"></i>
                                                                        <p className='mb-0 ps-3'>For tokens with burns, rebase or other special transfers please ensure that you have a way to whitelist multiple addresses or turn off the special transfer events (By setting fees to 0 for example for the duration of the presale)</p>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            {deposit > 0 ?
                                                                <div className='col-12'>
                                                                    <div className='note_desc mt-1 mb-1 text-center'>
                                                                        <p>Need {parseFloat(deposit).toFixed(2)} {tokeninfo?.symbol} to create launchpad.</p>
                                                                    </div>
                                                                </div> : <></>}

                                                            <div className='col-12 col-md-12 text-center'>
                                                                <button className="orange_small_secondary  me-2" onClick={() => setCurrentstep(3)}>
                                                                    Back
                                                                </button>


                                                                {/* <Link to="/launchpaddetail" className="orange_small_primary " onClick={()=>{this.setState({ currentStep: 3})}}>
                           Submit
                        </Link> */}
                                                                {console.log("salesssss", createdSale == '' || createdSale == ZEROTH_ADDRESS, createdSale == '', createdSale == ZEROTH_ADDRESS)}

                                                                {!isDeposited ?
                                                                    (createdSale == '' || createdSale == ZEROTH_ADDRESS ?
                                                                        <a onClick={() => { CreateSale() }} className="orange_small_primary">
                                                                            Create
                                                                        </a> :
                                                                        renderDeposit()) : <a onClick={() => { goToSale() }} className="orange_small_primary">Proceed to Sale</a>
                                                                }

                                                                {/* <a className="orange_small_primary ">Proceed to Sale</a> */}

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default CreateNativeLaunchpad;