import toast from "react-hot-toast";
import { NATIVE_PROXY, NATIVE_PROXY_ABI, NATIVE_SALE_ABI } from "../Config/abi/NativeProxy";
import { getWalletAddress } from "../Lib/localStorage";
import { UseTokenInfo, checkChainId } from "./useContract";
import { useWeb3 } from "./useWeb3";
import { CreateNativeLaunchpadHooks } from "./Usebackendaxios";

export const UseProxySale = async () => {
    try {
        const web3 = await useWeb3();
        const chainId = await web3.eth.getChainId();
        checkChainId(chainId);
        const proxysale = NATIVE_PROXY;

        const contract = new web3.eth.Contract(NATIVE_PROXY_ABI, proxysale);
        console.log("proxysale", contract);
        return contract;
    }
    catch (err) {
        console.log("UseProxySale", err.code, err)
    }
}


export const getSaleAddress = async (tokenAddress) => {
    try {
        const proxyContract = await UseProxySale();
        const sale = await proxyContract.methods.getSale(tokenAddress).call()
        return sale;
    }
    catch (err) {
        console.log("getSaleAddress", err.code, err)
    }
}

export const getProxyOwner = async () => {
    try {
        const proxy = await UseProxySale();
        const owner = await proxy.methods.owner().call();

        return owner;
    }
    catch (err) {
        console.log("getProxyOwner", err.code, err)
    }
}

export const createNativePresale = async (token, values, setters, details, account, data, id) => {
    try {
        console.log("createsale", token, values, setters, details, account, data, id);
        let presale = await InitiatePresale(token, values, setters, details, account);
        console.log("presale", presale)
        console.log("token[0] in create presale", token[0], token, values, setters, details, account);
        let sale = await getSaleAddress(token[0]);
        console.log("sale in createpresale", sale);
        if (sale == "0x0000000000000000000000000000000000000000") {
            setTimeout(async() => {
                sale = await getSaleAddress(token[0]);
                let wallet = getWalletAddress();
                wallet = wallet.toString();
                wallet = wallet.toLowerCase();
                console.log("wallet", wallet);
                let payload = {
                    owner: wallet,
                    saleaddress: sale,
                    status: data,
                    id: id
                }
                let createlaunchpad = await CreateNativeLaunchpadHooks(payload);
                console.log("create_launchpadhook", createlaunchpad);
                return sale;
            } , 3000)
        }
        else {
            let wallet = getWalletAddress();
            wallet = wallet.toString();
            wallet = wallet.toLowerCase();
            console.log("wallet", wallet);
            let payload = {
                owner: wallet,
                saleaddress: sale,
                status: data,
                id: id

            }
            let createlaunchpad = await CreateNativeLaunchpadHooks(payload);
            console.log("create_launchpadhook", createlaunchpad);

            return sale;
        }
        // if (sale == "0x0000000000000000000000000000000000000000") {
        //     sale = await getSaleAddress(token[0]);
        //     return sale;
        // }
        // else {
        //     return sale;
        // }
    }
    catch (err) {
        console.log("createPresale", err.code, err)
    }
}

export const InitiatePresale = async (token, values, setters, details, account) => {
    try {
        // debugger
        console.log("initialpresale_token", token);
        console.log("initialpresale_values", values);

        console.log("initialpresale_setters", setters);

        console.log("initialpresale_details", details);

        console.log("initialpresale_account", account);

        const proxyContract = await UseProxySale();
        let deploymentFee
        const owner = await getProxyOwner()
        if (owner !== account) {
            deploymentFee = await proxyContract.methods.getDeploymentFee().call();
        }
        else {
            deploymentFee = 0
        }
        const data = proxyContract.methods.createPresale(token, values, setters, details).send({ from: account, value: deploymentFee })
        console.log("data in initiate presale", data);
        await toast.promise(data, {
            loading: 'Creating New Presale ...',
            success: 'Presale Created Successfully',
            error: (err) => `Error : ${err.toString()}`,
        }
        );
    }
    catch (err) {
        console.log("InitiatePresale", err.code, err)
    }
}

export const getTotalNativeSalesInfo = async () => {
    try {
        const proxy = await UseProxySale();

        let data = await proxy.methods.getTotalSales().call();
        console.log("proxy inside getTotalSales info:", data);
        const reverseArray = [...data].reverse();
        return (reverseArray);
    }
    catch (e) {
        console.log("getTotalSalesInfo", e);
    }
}

export const UseNativeSale = async (VALUE) => {
    try {
        const web3 = await useWeb3();
        const contract = new web3.eth.Contract(NATIVE_SALE_ABI, VALUE);
        console.log("contract", contract.methods)
        return contract;
    }
    catch (err) {
        console.log("UseSale", err)
    }
}

export const getSaleInfoCard = async (saleAddress) => {
    try {
        if (saleAddress) {
            console.log(saleAddress, 'saleAddress')
            const saleContract = await UseNativeSale(saleAddress);
            const data = await saleContract.methods.getSaleInfo().call();
            console.log("new", data);
            return data;
        }
        else {
            return {}
        }
    }
    catch (e) {
        console.log("getSaleInfoCard_err", e)
    }

}


export const getnativelaunchpaddatalimit = async (cards, start, end) => {
    try {
        let SaleInfoCards = [];
        console.log("card", cards, start, end, cards.length);
        if (cards.length > 0 && cards.length > start) {
            for (var i = start; i < end; i++) {
                console.log("forlop", i);
                if (cards.length > i) {
                    console.log("card[i]", cards[i], i);
                    if (cards[i]?.isdb == true || cards[i]?.isdb == "true") {
                        SaleInfoCards.push(cards[i]);

                    }
                    else {
                        var saleInfo = await getSaleInfoCard(cards[i]?._sale ? cards[i]?._sale : cards[i]?.saleaddress);

                        console.log("saleInfo2", saleInfo)
                        if (saleInfo?.useWithToken == "0x0000000000000000000000000000000000000000") {
                            saleInfo = { ...saleInfo, ...{ coin: "BNB" } };
                            console.log("saleInfo_saleInfo", saleInfo)

                            SaleInfoCards.push(saleInfo);
                            console.log("bnb", SaleInfoCards);
                        }
                        else {
                            const newdata = await UseTokenInfo(saleInfo.useWithToken);
                            saleInfo = { ...saleInfo, ...{ coin: newdata?.symbol } };
                            SaleInfoCards.push(saleInfo);
                        }
                    }
                }
                else {
                    console.log("other", SaleInfoCards);
                    return SaleInfoCards;
                }
            }
        }
        console.log("other", SaleInfoCards);
        return SaleInfoCards;
    } catch (err) {
        console.log(err, "getlaunchpaddatalimit__err")
    }
}


export const updateStopEventnative = async (saleAddress, account) => {
    try {console.log("stoppppppppppppppppppppppppppppppppppppppppppp");
        const saleContact = await UseNativeSale(saleAddress);
        const data = saleContact.methods.closePresale().send({ from: account });
        await toast.promise(data, {
            loading: 'Manual Stop Sale event ...',
            success: 'Sale Stopped Successfully',
            error: 'Error ! When Stopping sale',
        }
        );
        window.location.reload();
    }
    catch (err) {
        console.log("updateStopEvent", err)
    }
}


export const getUnsoldnative = async (saleAddress, account) => {
    try {
        const saleContact = await UseNativeSale(saleAddress);
        const data = saleContact.methods.getUnsoldTokens().send({ from: account });
        console.log("data", data)
        await toast.promise(data, {
            loading: 'Withdraw Un Sold Tokens ...',
            success: 'Withdrawn Successfully',
            error: 'Error ! When Withdraw',
        }
        );
        window.location.reload();
    }
    catch (err) {
        console.log("getUnsold", err)
    }
}


export const withdrawBNBnative = async (saleAddress, account) => {
    try {
        const saleContact = await UseNativeSale(saleAddress);
        const data = saleContact.methods.withdrawBNB().send({ from: account });
        await toast.promise(data, {
            loading: 'Withdraw ...',
            success: 'Withdrawn Successfully',
            error: 'Error ! When Withdraw ',
        }
        );
        window.location.reload();
    }
    catch (err) {
        console.log("withdrawBNB", err)
    }
}


export const Finalisenative = async (saleAddress, account) => {
    try {
        const saleContact = await UseNativeSale(saleAddress);
        const data = saleContact.methods.finalizeSale().send({ from: account });
        await toast.promise(data, {
            loading: 'Finalizing the sale ...',
            success: 'Users can Claim their Tokens Now !',
            error: 'Error ! When Finalising',
        }
        );
        window.location.reload();
    }
    catch (err) {
        console.log("Finalise", err)
    }
}

export const Getliquiditytokenamountnative = async (address) => {
    try {
        console.log("addressaddress", address);
        const proxyContract = await UseNativeSale(address);
        const data = await proxyContract.methods.getLiquidityTokenAmount().call();
        console.log("data in withdraw", data);
        return data;
    }
    catch (e) {
        console.log("Getliquiditytokenamount", e)
    }
}

export const Getunsoldtokennative = async (address) => {
   
        try {
            const proxyContract = await UseNativeSale(address);
            const data = await proxyContract.methods.getUnsoldTokensBalance().call();
            console.log("data in withdraw", data);
            return data;
        }
        catch (err) {
            console.log("Getunsoldtoken", err)
        }
}

export const GetSalePerAccountnative = async (account, saleAddress) => {
    try {
        // console.log("User data " ,account )
        const saleContract = await UseNativeSale(saleAddress);
        const userData = await saleContract.methods.getUserInfo(account).call();


        return userData;
    }
    catch (err) {
        console.log("GetSalePerAccount", err.code)
    }
}