import logo from '../src/assets/images/headerlogo.png';
import './App.css';
import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import Dashboard from './Screens/Dashboard';
import HelperRoute from './helperRoutes';
import '../src/assets/css/Style.css'
import UserWallet from './Screens/UserWallet';
import ResetPassword from './Screens/ResetPassword';
import Launchpadlist from './Screens/Launchpadlist';
import Tokenreqlist from './Screens/Tokenreqlist';
import Tokenreqdetail from './Screens/Tokenreqdetail';
import SupportTicket from './Screens/SupportTicket';
import Profile from './Screens/Profile';
import Settings from './Screens/Settings';
import AddSettings from './Screens/AddSettings';
import LaunchpadSettings from "./Screens/LaunchpadSettings";
// import AddNetwork from './Screens/';
import EmailVerification from './Screens/EmailVerification';
import CreateLaunchpad from "./Screens/CreateLaunchpad";
import LaunchpadDetail from "./Screens/LaunchpadDetail";


import FeeSettings from "./Screens/FeeSettings";
import AddFee from "./Screens/AddFee";




import Login from "./Screens/Login";
import Forgotpassword from "./Screens/Forgotpassword";
import Resetnewpassword from "./Screens/Resetnewpassword";
import store from "./store";
import { Provider } from "react-redux";
import ConditionRoute from "./ConditionalRoute";
import TokenreqDetail from './Screens/Tokenreqdetail';
import Tokenreqedit from './Screens/Tokenreqedit';
import AddRegisteraccount from './Screens/RegisterAccount/addRegisteraccount'
import ListRegisteraccount from './Screens/RegisterAccount/listRegisteraccount'
import EditRegisteraccount from './Screens/RegisterAccount/editRegisteraccount'
import Cmslist from './Screens/cmslist';
import Editcmslist from './Screens/editcms';

import Faqlist from './Screens/Faq/faqlist';
import Faqtable from './Screens/Faq/faqtable';
import Faqedit from './Screens/Faq/faqedit';
import Faqadd from './Screens/Faq/faqadd'
import AddSprofeature from './Screens/SpropadFeature/addSprofeature'
import EditSprofeature from './Screens/SpropadFeature/editSprofeature'
import ListSprofeature from './Screens/SpropadFeature/listSprofeature'
import Allocationlist from './Screens/Allocationlist';
import Addallocation from './Screens/AddAllocation';
import Editallocation from './Screens/EditAllocation';

import AddSocial from './Screens/SocialLink/addSocial'
import EditSocial from './Screens/SocialLink/editSocial'
import ListSocial from './Screens/SocialLink/listSocial'
import Interestlist from './Screens/InterestList';
import CreateNativeLaunchpad from './Screens/CreateNativeLaunchpad';
import NativeLaunchpadlist from './Screens/NativeLaunchpadlist';
import NativeLaunchpadDetail from './Screens/NativeLaunchpadDetail';



function App() {
  return (
    <>
      <Provider store={store}>
        <BrowserRouter >
          <div><Toaster /></div>

          <HelperRoute />
          <Switch>

            {/* <Routes> */}


            <Route
              exact
              path="/resetpassword/:authToken"
              component={Resetnewpassword}
            />

            {/* <Route path='/resetpassword/:authToken' element={<Resetnewpassword />}/>   */}

            <Route
              exact
              path="/verify-old-email/:authToken"
              component={EmailVerification}
            />

            {/* <Route path ='/verify-old-email/:authToken' element={<EmailVerification />}/> */}

            <Route
              exact
              path="/verify-new-email/:authToken"
              component={EmailVerification}
            />

            <ConditionRoute
              exact
              path="/"
              component={Login}
              type={"auth"}
            />

            {/* <Route path='/' element={<Login />} /> */}


            <ConditionRoute
              exact
              path="/login"
              component={Login}
              type={"auth"}
            />

            {/* <Route path='/login' element={<Login />} /> */}

            <ConditionRoute
              exact
              path="/forgotpassword"
              component={Forgotpassword}
              type={"public"}
            />

            {/* <Route path='/forgotpassword' element={<Forgotpassword />} /> */}

            <ConditionRoute
              exact
              path="/resetpassword"
              component={Resetnewpassword}
              type={"public"}
            />
            {/* <Route path='/resetpassword' element={<Resetnewpassword />} /> */}

            <ConditionRoute
              exact
              path="/addallocation"
              component={Addallocation}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/editallocation"
              component={Editallocation}
              type={"private"}
            />

            {/* <Route path ='/verify-new-email/:authToken' element={<EmailVerification />} /> */}

            <ConditionRoute
              exact
              path="/dashboard"
              component={Dashboard}
              type={"private"}
            />

            {/* <Route path='/dashboard' element={<Dashboard />} /> */}

            <ConditionRoute
              exact
              path="/userWallet"
              component={UserWallet}
              type={"private"}
            />
            {/* <Route path='/userWallet' element={<UserWallet />} /> */}

            <ConditionRoute
              exact
              path="/supportTicket"
              component={SupportTicket}
              type={"private"}
            />

            {/* <Route path='/supportTicket' element={<SupportTicket />} /> */}

            <ConditionRoute
              exact
              path="/launchpadlist"
              component={Launchpadlist}
              type={"private"}
            />

            <ConditionRoute
              exact
              path="/tokenreqlist"
              component={Tokenreqlist}
              type={"private"}
            />

            <ConditionRoute
              exact
              path="/interest-list"
              component={Interestlist}
              type={"private"}
            />

            <ConditionRoute
              exact
              path="/tokenreqedit"
              component={Tokenreqedit}
              type={"private"}
            />

            <ConditionRoute
              exact
              path="/allocationlist"
              component={Allocationlist}
              type={"private"}
            />


            <ConditionRoute
              exact
              path="/tokenreqdetail"
              component={TokenreqDetail}
              type={"private"}
            />

            {/* <Route path='/launchpadlist' element={<Launchpadlist />} /> */}

            <ConditionRoute
              exact
              path="/profile"
              component={Profile}
              type={"private"}
            />

            {/* <Route path='/profile' element={<Profile />} /> */}
            <ConditionRoute
              exact
              path="/settings"
              component={Settings}
              type={"private"}
            />

            {/* <Route path='/settings' element={<Settings />} /> */}

            <ConditionRoute
              exact
              path="/changePassword"
              component={ResetPassword}
              type={"private"}
            />

            {/* <Route path='/changePassword' element={<ResetPassword />} /> */}
            <ConditionRoute
              exact
              path="/addSettings"
              component={AddSettings}
              type={"private"}
            />

            {/* <Route path='/addSettings' element={<AddSettings />} /> */}

            <ConditionRoute
              exact
              path="/launchpadSettings"
              component={LaunchpadSettings}
              type={"private"}
            />

            {/* <Route path='/feeSettings' element={<FeeSettings />} /> */}

            <ConditionRoute
              exact
              path="/feeSettings"
              component={FeeSettings}
            // type={"private"}
            />

            <ConditionRoute
              exact
              path="/addFee"
              component={AddFee}
            // type={"private"}
            />


            {/* <ConditionRoute
              exact
              path="/addNetwork"
              component={AddNetwork}
              type={"private"}
            />

            <Route path='/addNetwork' element={<AddNetwork />} /> */}

            <ConditionRoute
              exact
              path="/createLaunchpad"
              component={CreateLaunchpad}
              type={"private"}
            />

          <ConditionRoute
              exact
              path="/create-native-launchpad"
              component={CreateNativeLaunchpad}
              type={"private"}
            />


            <ConditionRoute
              exact
              path="/native-launchpad-list"
              component={NativeLaunchpadlist}
              type={"private"}
            />

            <ConditionRoute
              exact
              path="/native-launchpad-detail/:saleaddress"
              component={NativeLaunchpadDetail}
              type={"private"}
            />
            {/* <Route path='/createLaunchpad' element={<CreateLaunchpad/>} /> */}

            <ConditionRoute
              exact
              path="/launchpadDetail/:saleAddress"
              component={LaunchpadDetail}
              type={"private"}
            />
            {/* addRegisteraccount */}
            <ConditionRoute
              exact
              path="/addRegisteraccount"
              component={AddRegisteraccount}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/listRegisteraccount"
              component={ListRegisteraccount}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/editRegisteraccount"
              component={EditRegisteraccount}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/cmslist"
              component={Cmslist}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/editcms/:data"
              component={Editcmslist}
              type={"private"}
            />

            <ConditionRoute
              exact
              path="/faqlist"
              component={Faqlist}
              type={"private"}
            />


            <ConditionRoute
              exact
              path="/faqtable/:data"
              component={Faqtable}
              type={"private"}
            />

            <ConditionRoute
              exact
              path="/editfaq/:data"
              component={Faqedit}
              type={"private"}
            />

            <ConditionRoute
              exact
              path="/addfaq/:data"
              component={Faqadd}
              type={"private"}


            />

            <ConditionRoute
              exact
              path="/addSprofeature"
              component={AddSprofeature}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/editSprofeature"
              component={EditSprofeature}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/listSprofeature"
              component={ListSprofeature}
              type={"private"}


            />

            <ConditionRoute
              exact
              path="/addSocial"
              component={AddSocial}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/editSocial"
              component={EditSocial}
              type={"private"}
            />
            <ConditionRoute
              exact
              path="/listSocial"
              component={ListSocial}
              type={"private"}


            />

            {/* <Route path='/launchpadDetail' element={<LaunchpadDetail/>} /> */}


            {/* </Routes> */}
          </Switch>

        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
