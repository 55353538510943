import React, { useEffect, useState } from "react";
import {
    useParams,
    useHistory,
    useLocation
} from "react-router-dom";
// import { useTranslation } from 'react-i18next';

// import action
import {
    sentVerifyLink,
    verifyNewEmail
} from '../Actions/LoginActions';

import toast from "react-hot-toast";


// import lib
// import { toastAlert } from "../Lib/toastAlert";

const EmailVerification = (props) => {
    const { authToken } = useParams();
    // const { t, i18n } = useTranslation();
    const history = useHistory();
    const location = useLocation();

    // state
    const [page, setPage] = useState('loading')

    // function
    // const emailActivation = async () => {
    //     const { status, message } = await userEmailActivation({ userId: authToken });
    //     if (status == 'success') {
    //         history.push("/login");
    //         toastAlert('success', message, 'emailActivation', 'TOP_RIGHT');
    //     } else if(status == 'failed') {
    //         history.push("/login");
    //         toastAlert('error', message, 'emailActivation', 'TOP_RIGHT');
    //     }
    //     else {
    //         setPage('error');
    //     }
    // }

    const verifOldEmail = async () => {
        console.log("verifOldEmail", authToken)
        const verify = await sentVerifyLink({ token: authToken });
        console.log(verify, "verifOldEmail")
        if (verify?.data?.data?.status) {
            console.log(verify, "verifOldEmail")
            history.push("/login");
            toast.success(verify?.data?.data?.message);
        } else {
            setPage('error');
        }
    }

    const verifNewEmail = async () => {
        console.log("newEmail")
        const newEmail = await verifyNewEmail({ token: authToken });
        console.log("newEmail", newEmail)
        if (newEmail?.data?.data?.status) {
            history.push("/login");
            toast.success(newEmail?.data?.data?.message);
        } else {
            setPage('error');
            history.push("/login");
        }
    }


    useEffect(() => {
        let pathname = location.pathname;
        console.log("pathname" , pathname);
        if (pathname == '/verify-old-email/' + authToken) {
            console.log(pathname, "authTokenauthToken")
            verifOldEmail();
        } else if (pathname == '/verify-new-email/' + authToken) {
            verifNewEmail();
        }
    }, [authToken])

    return (
        <>
            {
                page == 'loading' && <p>{('LOADING')}</p>
            }
            {
                page == 'error' && <p>{('INVALID_URL')}</p>
            }
        </>
    )


}

export default EmailVerification;